import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";

export interface IDeactivateComponent {
    canExit: () => Observable<boolean> | Promise<boolean> | boolean;
  }
  

@Injectable({
    providedIn: 'root'
})
export class ComponentDeactivateCheckGuard implements CanDeactivate<IDeactivateComponent> {

    constructor() { }

    public canDeactivate(
        component: IDeactivateComponent
      ): Observable<boolean> | Promise<boolean> | boolean {
        return component.canExit ? component.canExit() : true;
      }
 
}