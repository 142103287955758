import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'bhive-loading',
  templateUrl: './bhive-loading.component.html',
  styleUrls: ['./bhive-loading.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class BhiveLoadingComponent implements OnInit {
  
  @Input() width?: number;
  @Input() height?: number;
  
  ngOnInit(): void {
  }

}