<div class="drop-zone upload-box" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)" id="onDragOver" [ngClass]="{'p-12':!isProfileImageUpload, 'p-2':isProfileImageUpload }">
    <mat-icon *ngIf="!isProfileImageUpload" class="upload-icon">cloud_upload</mat-icon>
    <mat-icon *ngIf="isProfileImageUpload" class="upload-icon-small">cloud_upload</mat-icon>
    <br>
    <label for="fileInput"> {{'DRAG_DROP_FILE_UPLOAD_COMPONENT.DRAG_AND_DROP_FILE_HERE' |translate}}</label>
    <br>

    <label for="fileInput"> {{'DRAG_DROP_FILE_UPLOAD_COMPONENT.OR' |translate}} </label>
    <br>

    <input type="file" [multiple]="isMultipleFile" (change)="onFileSelected($event)" #fileInput style="display: none"
        accept="{{accept}}" id="documentinput">


    <button class="btn btn-primary" (click)="fileInput.click()" id="documentBtn">
        {{'DRAG_DROP_FILE_UPLOAD_COMPONENT.BROWSE_FOR_FILE' |translate}}</button>

    <br>

    <ul class="file-list">
        <li *ngFor="let file of uploadedFiles;let i=index;">
            {{ file.name }} ({{ formatFileSize(file.size) }}) <mat-icon (click)="deleteSelectedFile(file)"
                id="deletDoc_{{i}}">delete</mat-icon>
        </li>
    </ul>
</div>