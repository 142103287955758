<div class="save-dialog">
  <h4 mat-dialog-title style="margin-bottom: 0px;">Save dashboard</h4>
  <mat-divider style="margin-bottom: 25px;"></mat-divider>
  <mat-dialog-content>
    <form (ngSubmit)="onSubmit($event)" #saveForm="ngForm">
      <div class="form-group flex">
        <label for="name" style="width: 12%;">Name</label>
        <input type="text" class="form-control" id="name" required [(ngModel)]="data.dbJson.name" name="name" #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Name is required
        </div>
      </div>

      <div class="form-group flex">
        <label for="alterEgo" style="width: 12%;">Is Home</label>
        <input type="checkbox" id="isHome" [(ngModel)]="data.dbJson.isHome" name="isHome">
      </div>
    </form>
  </mat-dialog-content>
  <mat-divider style="margin-bottom: 10px;"></mat-divider>
  <mat-dialog-actions align="end">
    <button *ngIf="data.addingMode" type="submit" class="px-6 mat-button-base mat-primary mat-flat-button" style="background-color: #58C5E3;" (click)="saveForm.ngSubmit.emit($event)"
      [disabled]="!saveForm.form.valid" name="new">Save</button>
    <!-- <button *ngIf="!data.addingMode" type="submit" class="px-6 mat-button-base mat-primary mat-flat-button" style="background-color: color(srgb 0.15 0.665 0.0698 / 0.72);" (click)="saveForm.ngSubmit.emit($event)"
      [disabled]="!saveForm.form.valid" name="new">Create new</button> -->
      <button *ngIf="!data.addingMode" type="submit" mat-button (click)="saveForm.ngSubmit.emit($event)" cdkFocusInitial [disabled]="!saveForm.form.valid">
          <mat-icon class="confirm-dialog-icons" svgIcon="create"></mat-icon>Create new                         
      </button> 
    <!-- <button *ngIf="!data.addingMode" type="submit" class="px-6 mat-button-base mat-primary mat-flat-button"  (click)="saveForm.ngSubmit.emit($event)" cdkFocusInitial
      [disabled]="!saveForm.form.valid" name="edit">Update</button> -->
    <button *ngIf="!data.addingMode" type="submit" mat-button (click)="saveForm.ngSubmit.emit($event)" cdkFocusInitial [disabled]="!saveForm.form.valid">
          <mat-icon class="confirm-dialog-icons" svgIcon="update"></mat-icon>Update                            
      </button> 
  </mat-dialog-actions>
</div>