<mat-stepper linear #stepper>
  <!-- <mat-step [stepControl]="firstFormGroup" errorMessage="Name is required.">
    <form [formGroup]="firstFormGroup">
    <ng-template matStepLabel>Comparison type</ng-template>
    <ng-template matStepContent> 
        <mat-button-toggle-group class="compare-stepper-matButtonToggleGroup" #group="matButtonToggleGroup" formControlName="comparisonType">
          <mat-button-toggle value="year">
            Yearly
          </mat-button-toggle>
          <mat-button-toggle value="quarter">
            Quarterly
          </mat-button-toggle>
          <mat-button-toggle value="month">
            Monthly
          </mat-button-toggle>
        </mat-button-toggle-group>
        <div>
           <button color="primary" class="compare-stepper-next" matStepperNext mat-raised-button>Next</button>
        </div>
      </ng-template>
      </form>
    
  </mat-step> -->
  <mat-step [stepControl]="secondFormGroup" errorMessage="Name is required.">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Choose first timespan</ng-template>
      <ng-template matStepContent>
        <div class=" flex justify-center" *ngFor="let dateRange of dateRanges; let i = index" [formGroup]="dateRange">
          <mat-form-field class="compare-stepper-timespan">
            <mat-date-range-input [rangePicker]="datePicker">
              <input matStartDate placeholder="Start date" formControlName="firstStartDate" required>
              <input matEndDate placeholder="End date" formControlName="firstEndDate" required>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #datePicker></mat-date-range-picker>
          </mat-form-field>
          <button mat-mini-fab color="primary" mat-raised-button class="ml-2" (click)="removeDateRange(i)">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
        <div class="flex justify-center">
          <button mat-mini-fab color="primary" mat-raised-button class="ml-2" (click)="addDateRange()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div>
          <button color="primary" class="compare-stepper-next" (click)="compareData()" matStepperNext
            mat-raised-button>Next</button>
        </div>
      </ng-template>
    </form>
  </mat-step>

  <!-- <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
    <ng-template matStepLabel>Choose second timespan</ng-template>
    <ng-template matStepContent>  
        <mat-form-field class="compare-stepper-timespan">
          <mat-date-range-input [rangePicker]="secondPicker">
            <input matStartDate placeholder="Start date" formControlName="secondStartDate" required>
            <input matEndDate placeholder="End date" formControlName="secondEndDate" required>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="secondPicker"></mat-datepicker-toggle>
          <mat-date-range-picker #secondPicker></mat-date-range-picker>
        </mat-form-field>
        <div>
          <button color="primary" class="compare-stepper-next" (click)="compareData()" matStepperNext mat-raised-button>Next</button>
        </div>
      </ng-template>
      </form>
    
  </mat-step> -->

  <mat-step>
    <ng-template matStepLabel>Compare data</ng-template>
    <ng-template matStepContent>
      <mat-card class="compare-stepper-mat-card">
        <div class="chart_title_compare">{{data.data.Name}}</div>
        <mat-divider></mat-divider>
        <form [formGroup]="firstFormGroup">
          <mat-button-toggle-group class="compare-stepper-matButtonToggleGroup" #group="matButtonToggleGroup" (change)="compareData()"
            formControlName="comparisonType">
            <mat-button-toggle value="year">
              Yearly
            </mat-button-toggle>
            <mat-button-toggle value="quarter">
              Quarterly
            </mat-button-toggle>
            <mat-button-toggle value="month">
              Monthly
            </mat-button-toggle>
            <mat-button-toggle value="null">
              None
            </mat-button-toggle>
          </mat-button-toggle-group>
        </form>
        <bhive-dashboard-item #comparison [height]="data.height" [data]="data.data" [visualCode]="data.visualCode"
          [filters]="data.filters" [fullItem]="data">
        </bhive-dashboard-item>
      </mat-card>
      <div class="compare-stepper-buttons">
        <button mat-raised-button matStepperPrevious style="margin-right:10px;">Back</button>
        <button mat-raised-button (click)="stepper.reset()">Reset</button>
      </div>
    </ng-template>
  </mat-step>
</mat-stepper>