import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import cubejs from '@cubejs-client/core';
import { EMPTY, Observable, Subject, Subscription, catchError, from, map, subscribeOn, switchMap, takeUntil, throwError, forkJoin, of } from 'rxjs';
import { DashboardQueryInterface, DashboardFilterInterface, VisualType, Configs } from './bhive-dashboard-config.interface';
import { DashboardBuilderJson, DashboardJSonModel } from './bhive-dashboard.interface';
import * as XLSX from 'xlsx';
import { tableHeadersTranslations, FilterKeys, SeriesColorMap } from './keyword-mapping'
import { MatDialog } from '@angular/material/dialog';
import { BhiveDashboardEnvironmentService } from './bhive-dashboard-environment.service';
import { DrillDownDialog } from './drilldown-dialog.component';
const EXCEL_EXTENSION = '.xlsx';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class BhiveDashboardService {

    private cubejs: CubejsApi
    public baseHref = this.environment.getApiUrl();;
    //private baseHref = 'http://localhost:8085';
    private cubeAPI = this.environment.getCubeApiUrl();
    // private cubeAPI = "http://localhost:4000/cubejs-api/v1";
    private complianceCriteria = null;
    private subscribe$ = new Subscription();
    private brandid: number | undefined;
    private filterSubscribe$ = new Subscription();
    public filterHistory: any[] = [];
    public translations: any;

    constructor(private http: HttpClient, public dialog: MatDialog, private environment: BhiveDashboardEnvironmentService) { }
    cancelRequests() {
        this.subscribe$.unsubscribe();
        this.filterSubscribe$.unsubscribe();
    }
    private getAuthorization(factoryId?: number, brandId?: number, onlyScope?: boolean, includeCommodities?: boolean): Promise<void> {
        return new Promise<void>((resolve) => {
            let req = this.http.get<string>(this.baseHref + '/getFactoryCubeToken/' + factoryId + '/' + brandId + '/' + onlyScope + '/' + includeCommodities);
            // this.unsubscribe$ = req;
            this.subscribe$ = req.subscribe((res: any) => {
                this.cubejs = cubejs(
                    async () => res['token'],
                    { apiUrl: this.cubeAPI }
                );
                resolve();
            },
                (err) => {
                    throwError(new Error('An error ocurred while getting the Cube token!'));
                });
        });
    }
    async getCubeJSData(query: Object, factoryId?: number, brandId?: number, onlyScope?: boolean, includeCommodities?: boolean): Promise<any> {
        return this.getAuthorization(factoryId, brandId, onlyScope, includeCommodities).then(() => this.cubejs.load(query))
            .then(resultSet => resultSet)
            .catch(error => Promise.reject(error));
    }



    private changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
        var newArr = [];
        for (var i = 0; i < arr.length; i++) {
            var obj: any = arr[i];
            obj[newKey] = (newKey == "value" && obj[originalKey] === null) ? 0 : obj[originalKey];
            delete (obj[originalKey]);
            newArr.push(obj);
        }
        return newArr;
    }

    getDashboardConfigs(): Observable<Configs> {
        return this.http.get(this.baseHref + '/getDashboardConfigs')
            .pipe(
                map((res: any) => {
                    const configs: Configs = {
                        queries: res['queries'].map((element: any) => ({
                            Id: element['id'],
                            Name: element['name'],
                            Description: element['description'],
                            QueryJSON: element['query_json'],
                            Active: element['active'],
                            VisualType: element['default_visual_type_id'],
                            NeedsCompliance: element['needs_compliance'],
                            RawQueryId: element['raw_query_id'],
                            DrillQueryId: element['drill_query_id'],
                            HasTarget: element['has_target'],
                            CanCompare: element['can_compare'],
                            GetLatestChemicals: element['get_latest_chemicals'],
                            FilterConfig: JSON.parse(element['filter_config']),
                            Configs: element['configs'].map((el: any) => ({
                                Code: el['Code'],
                                QueryPivotConfig: JSON.parse(el['QueryPivotConfig']),
                                Groupings: JSON.parse(el['Groupings']),
                                InteractionConfig: JSON.parse(el['InteractionConfig'])
                            }))
                        })),
                        filters: res['filters'].map((element: any) => ({
                            Id: element['id'],
                            Type: element['type'],
                            ShowName: element['name'],
                            Name: element['cube_filter_name'],
                            QueryJSON: element['query_json']
                        })),
                        standards: res['standards'].map((element: any) => ({
                            Name: element['name'],
                            Id: element['id'],
                            DisplayName: element['displayName']
                        })),
                        visuals: res['visuals'].map((element: any) => ({
                            Id: element['id'],
                            Name: element['name'],
                            Code: element['code']
                        }))
                    };

                    return configs;
                })
            );
    }
    async getChartData(queryObj: DashboardQueryInterface, visualCode: string, filters: Array<any>, organization_id: any): Promise<any> {
        if (organization_id)
            this.brandid = organization_id;
        const pivotConfig: Object = queryObj.Configs.find(el => el.Code === visualCode)!.QueryPivotConfig;
        let query = JSON.parse(queryObj.QueryJSON);
        if (query.filters)
            query.filters.push(...this.prepareCubeJsFilters(filters, queryObj));
        try {
            if (queryObj.GetLatestChemicals) {
                query = await this.getFormulasFirst(query);
            }
            const data = await this.getCubeJSData(query, undefined, undefined, true, false);
            const result = await this.transformData(pivotConfig, visualCode, data, queryObj);
            const meta = data.tableColumns()
            return { result, data, meta };
        } catch (error) {
            console.error('Error in getChartData:', error);
            throw error; // Rethrow the error for further handling
        }
    }
    getChartTarget(queryObj: DashboardQueryInterface): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.http.get(this.baseHref + '/getQueryTarget/' + queryObj.Id)
                .subscribe(
                    data => {
                        // Resolve the promise with the received data
                        resolve(data);
                    }
                );
        });
    }
    async changeCompliance(newCompliance: any) {
        if (newCompliance.hasOwnProperty('id')) {
            this.complianceCriteria = newCompliance['id'];
        }
        else {
            this.complianceCriteria = newCompliance['name'];
        }
    }
    getVisualPreview(queryObj: DashboardQueryInterface, visualCode: string): Promise<any> {
        return this.http.get<string>(this.baseHref + '/getVisualPreview/' + queryObj.Id + '/' + visualCode)
            .toPromise();
    }

    fillFilterWithData(filter: DashboardFilterInterface): Observable<any[]> {
        if (filter.ShowName === "Standards") {
            return this.http.get<any>(this.baseHref + '/report/standards').pipe(
                map((response: any) => response['standards']),
                // catchError(this.handleError)
            );
        } else {
            return from(this.getCubeJSData(filter.QueryJSON, undefined, undefined, true, false)).pipe(
                map((result: any) => {
                    let data = result.tablePivot();
                    return this.changeKey(filter.Name, filter.ShowName, data)
                }),
                // catchError(this.handleError)
            );
        }
    }


    saveDashboard(dashboard: DashboardBuilderJson): Observable<any> {
        const dashJson = {
            items: dashboard.items,
            filters: dashboard.filters
        };

        const reqObj = {
            id: dashboard.id,
            name: dashboard.name,
            dashboardJSon: dashJson,
            isHome: dashboard.isHome
        };

        return this.http.post(this.baseHref + '/saveDashboard', reqObj);
    }

    saveQueryTarget(queryId: number, target: number): Observable<any> {
        const reqObj = {
            queryId: queryId,
            target: target
        };

        return this.http.post(this.baseHref + '/saveOrganizationQueryTarget', reqObj);
    }

    getDashboard(isHome: boolean, dashboardID?: number): Observable<any> {
        return this.http.get(this.baseHref + '/getDashboard/' + isHome + '/' + (dashboardID || ''))
            // No need to create a Subject, you can return the HTTP request as an observable
            // Also, use a ternary operator to handle optional dashboardID
            // Handle error cases as well
            .pipe(
                catchError(error => {
                    // Handle the error (e.g., log it or show a message)
                    console.error('Error in getDashboard:', error);
                    throw error; // Rethrow the error to the caller
                })
            );
    }

    getAllDashboards(): Observable<any> {
        return this.http.get(this.baseHref + '/getAllDashboards')
            .pipe(
                catchError(error => {
                    // Handle the error (e.g., log it or show a message)
                    console.error('Error in getDashboard:', error);
                    throw error; // Rethrow the error to the caller
                })
            );
    }

    drillDown(parentQuery: DashboardQueryInterface, visualCode: string, filters: Array<any>): Observable<any[]> {
        return this.getNewQueryObject(parentQuery.DrillQueryId!).pipe(
            switchMap(response => {
                return this.getChartData(response, visualCode, filters, this.brandid);
            })
        );
    }
    exportData(type: string, queryObj?: DashboardQueryInterface, filters?: Array<any>, dataPopUp?: Array<any>) {
        var subject = new Subject<Array<any>>();
        if (queryObj && filters) {
            switch (type) {
                case 'RAW':
                    let queryId: number = queryObj.RawQueryId || 0;
                    if (queryId !== 0) {
                        this.getNewQueryObject(queryId).subscribe(response => {
                            this.getChartData(response, "table", filters, this.brandid)
                                .then((data: any) => {

                                    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
                                    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                    XLSX.writeFile(myworkbook, 'test' + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });

                                })
                        });

                    }
                    else {
                        this.getChartData(queryObj, "table", filters, this.brandid)
                            .then(async (data: any) => { // Marking the function as async
                                if (this.isSpecialQuery(queryObj)) {
                                    if (queryObj.Name == 'Otto Activity Monitoring') {
                                        let excelData = [];
                                        excelData = data.result.map((obj: any) => {
                                            let commentDetails = obj.comment.commentDetails.map((comment: any) => {
                                                const formattedDate = new Date(comment.createdOn).toISOString().slice(0, 10);
                                                return `${formattedDate} - ${comment.userName}: ${comment.comment}`;
                                            });
                                            return {
                                                bhiveid: obj['BrandActivityWithCompliance.supplier_id'],
                                                zlkz: obj['BrandActivityWithCompliance.zlzk'],
                                                fid: obj['BrandActivityWithCompliance.fid'],
                                                vpid: obj['BrandActivityWithCompliance.vp_id'],
                                                factory_name: obj['BrandActivityWithCompliance.supplier_name'],
                                                country: obj['BrandActivityWithCompliance.country'],
                                                otto_scope: obj['BrandActivityWithCompliance.brand_scope'],
                                                last_scan_date: obj['BrandActivityWithCompliance.last_scan_date'] ? new Date(obj['BrandActivityWithCompliance.last_scan_date']).toISOString().slice(0, 10) : null,
                                                active: obj['BrandActivityWithCompliance.active'],
                                                connected: obj['BrandActivityWithCompliance.connected'],
                                                year: obj['BrandActivityWithCompliance.year'],
                                                nominated_by_ogc: obj['BrandActivityWithCompliance.nominated_by_vendor'],
                                                agency: obj['BrandActivityWithCompliance.nominated_by_agency'],
                                                q1_count: obj['BrandActivityWithCompliance.q1_count'],
                                                q1: obj['BrandActivityWithCompliance.q1'],
                                                q2_count: obj['BrandActivityWithCompliance.q2_count'],
                                                q2: obj['BrandActivityWithCompliance.q2'],
                                                q3_count: obj['BrandActivityWithCompliance.q3_count'],
                                                q3: obj['BrandActivityWithCompliance.q3'],
                                                q4_count: obj['BrandActivityWithCompliance.q4_count'],
                                                q4: obj['BrandActivityWithCompliance.q4'],
                                                comment: commentDetails.join(', ') // Combine comment details into a string
                                            };
                                        });
                                        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
                                        const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                        const headers = [
                                            "Bhive ID",
                                            "ZLKZ",
                                            "FID",
                                            "VPID",
                                            "Factory Name",
                                            "Country",
                                            "Otto Scope",
                                            "Last Scan Date",
                                            "Active",
                                            "Connected",
                                            "Year",
                                            "Nominated by OGC",
                                            "Agency",
                                            "CIL uploaded Q1",
                                            "Q1",
                                            "CIL uploaded Q2",
                                            "Q2",
                                            "CIL uploaded Q3",
                                            "Q3",
                                            "CIL uploaded Q4",
                                            "Q4",
                                            "Comment"
                                        ];
                                        XLSX.utils.sheet_add_aoa(myworksheet, [headers], { origin: "A1" });
                                        XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                        XLSX.writeFile(myworkbook, queryObj.Name + ' ' + new Date().toLocaleDateString() + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
                                    }

                                    else {
                                        if (queryObj.Name == "Quarterly Inventory Overview" || queryObj.Name == "Monthly Inventory Overview") {
                                            let excelData = [];
                                            excelData = data.result.map((obj: any) => {
                                                let commentDetails = obj.comment.commentDetails.map((comment: any) => {
                                                    const formattedDate = new Date(comment.createdOn).toISOString().slice(0, 10);
                                                    return `${formattedDate} - ${comment.userName}: ${comment.comment}`;
                                                });

                                                return {
                                                    ...obj, // spread the original object properties
                                                    comment: commentDetails.join(', ') // add the comment field with joined comment details
                                                };
                                            });
                                            const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
                                            const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                            const headers = queryObj.Name != "Quarterly Inventory Overview" ? [
                                                "Factory",
                                                "Scanned",
                                                "Marked as complete",
                                                "Total Number of Chemicals",
                                                "No. of Compliant Chemicals",
                                                "Compliance by Count (%)",
                                                "Compliance by consumption (%)",
                                                "Comment"

                                            ] : [
                                                "Factory",
                                                "Last Scan Date for selected Quarter",
                                                "Marked as complete",
                                                "Total Number of Chemicals",
                                                "No. of Compliant Chemicals",
                                                "Compliance by Count (%)",
                                                "Compliance by consumption (%)",
                                                "Comment"
                                            ];
                                            XLSX.utils.sheet_add_aoa(myworksheet, [headers], { origin: "A1" });
                                            XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                            XLSX.writeFile(myworkbook, queryObj.Name + ' ' + new Date().toLocaleDateString() + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
                                        }
                                        else {   
                                            if (queryObj.Name == "Higg Overview" || queryObj.Name == "Wastewater Overview" || queryObj.Name == "Wastewater Test Reports (v2.1) - Result Analysis") {
                                                let excelData = [];
                                                excelData = data.result.map((obj: any) => {
                                                    let commentDetails = obj.comment.commentDetails.map((comment: any) => {
                                                        const formattedDate = new Date(comment.createdOn).toISOString().slice(0, 10);
                                                        return `${formattedDate} - ${comment.userName}: ${comment.comment}`;
                                                    });

                                                    return {
                                                        ...obj, // spread the original object properties
                                                        comment: commentDetails.join(', ') // add the comment field with joined comment details
                                                    };
                                                });
                                                const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
                                                const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                                XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                                XLSX.writeFile(myworkbook, queryObj.Name + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });

                                            }
                                            else {
                                                if (queryObj.Name == "CAS Screener Report") {
                                                    let excelData = [];
                                                    excelData = data.result;
                                                    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
                                                    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                                    const headers =
                                                        [
                                                            "Factory Name",
                                                            "Country",
                                                            "Scan Month",
                                                            "Chemical Manufacturer",
                                                            "Chemical Name",
                                                            "Consumption",
                                                            "Substance Name",
                                                            "Substance concentration range",
                                                            "CasNr indicated by Factory",
                                                            "Result of Cas Screener",
                                                            "CAS Screener Standard - ZDHC MRSL V3.1",
                                                            "CAS Screener Standard - REACh SVHC",
                                                            "CAS Screener Standard - AFIRM RSL",
                                                            "CAS Screener Standard - other standards if any (optional)",
                                                            "Chemical Compliance",
                                                            "Verification standards",
                                                            "ZDHC Gateway Level"
                                                        ]

                                                    XLSX.utils.sheet_add_aoa(myworksheet, [headers], { origin: "A1" });
                                                    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                                    XLSX.writeFile(myworkbook, queryObj.Name + ' ' + new Date().toLocaleDateString() + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
                                                }
                                                else {
                                                    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.result);
                                                    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                                    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                                    XLSX.writeFile(myworkbook, queryObj.Name + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
                                                }
                                            }
                                        }
                                    }

                                }
                                else {
                                    if (queryObj.Name == "WM TUV BluWin Details" || queryObj.Name == "Walmart Overview" || queryObj.Name == "TUV BluWin Overview") {

                                        const walmart_overview =
                                            { "order": {}, "filters": [], "measures": [], "dimensions": ["WalmartOverview.total_count", "WalmartOverview.wm_connected", "WalmartOverview.tuv_connected", "WalmartOverview.bw_connected", "WalmartOverview.cil_count", "WalmartOverview.higg_self_count", "WalmartOverview.higg_verified_count", "WalmartOverview.ww_count"], "timeDimensions": [] }
                                        const tuv_bluwin =
                                            { "order": {}, "filters": [], "measures": [], "dimensions": ["TuvBluWinReview.status", "TuvBluWinReview.count"], "timeDimensions": [] }
                                        const wm_tuv_bluwin =
                                            { "order": {}, "filters": [], "measures": [], "dimensions": ["WmTuvBluWinDetails.supplier_id", "WmTuvBluWinDetails.supplier_number", "WmTuvBluWinDetails.mill_id", "WmTuvBluWinDetails.vendor_name", "WmTuvBluWinDetails.organization_type", "WmTuvBluWinDetails.name", "WmTuvBluWinDetails.activity", "WmTuvBluWinDetails.country_name", "WmTuvBluWinDetails.registration_date", "WmTuvBluWinDetails.username", "WmTuvBluWinDetails.email", "WmTuvBluWinDetails.disabled_account", "WmTuvBluWinDetails.connected_to_wm", "WmTuvBluWinDetails.connected_to_TUV", "WmTuvBluWinDetails.connected_to_BluWin", "WmTuvBluWinDetails.CIL", "WmTuvBluWinDetails.CIL_TUV_review", "WmTuvBluWinDetails.CIL_TUV_review_status", "WmTuvBluWinDetails.CIL_bw_review", "WmTuvBluWinDetails.CIL_bw_review_status", "WmTuvBluWinDetails.HIGG_self", "WmTuvBluWinDetails.HIGG_verified", "WmTuvBluWinDetails.HIGG_TUV_review", "WmTuvBluWinDetails.HIGG_TUV_review_status", "WmTuvBluWinDetails.HIGG_bw_review", "WmTuvBluWinDetails.HIGG_bw_review_status", "WmTuvBluWinDetails.WW", "WmTuvBluWinDetails.WW_TUV_review", "WmTuvBluWinDetails.WW_TUV_review_status", "WmTuvBluWinDetails.WW_bw_review", "WmTuvBluWinDetails.WW_bw_review_status", "WmTuvBluWinDetails.all_reviewed_tuv", "WmTuvBluWinDetails.at_least_one_review_tuv", "WmTuvBluWinDetails.all_reviewed_bw", "WmTuvBluWinDetails.at_least_one_review_bw"], "timeDimensions": [] }
                                        let walmart_overview_Data: any = [];
                                        let tuv_bluwin_Data: any = []
                                        let wm_tuv_bluwin_Data: any = [];

                                        forkJoin([
                                            this.getCubeJSData(walmart_overview, undefined, undefined, true, false),
                                            this.getCubeJSData(tuv_bluwin, undefined, undefined, true, false),
                                            this.getCubeJSData(wm_tuv_bluwin, undefined, undefined, true, false),
                                        ])
                                            .subscribe((response) => {
                                                let data1 = response[0]
                                                walmart_overview_Data = data1['loadResponse']['results'][0]['data'];

                                                let data2 = response[1];
                                                tuv_bluwin_Data = data2['loadResponse']['results'][0]['data'];

                                                let data3 = response[2];
                                                wm_tuv_bluwin_Data = data3['loadResponse']['results'][0]['data'];


                                                this.checkFileExists().subscribe(
                                                    (exists: boolean) => {
                                                        if (!exists) {
                                                            const workbook = new Workbook();
                                                            const worksheet1 = workbook.addWorksheet('Overview');
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.mergeCells('B1:E2');
                                                            worksheet1.getCell('B1').value = 'Walmart Mill Activity Development';
                                                            worksheet1.getCell('B1').font = { bold: true, size: 18 };
                                                            worksheet1.getCell('B1').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'EBEEF1' },
                                                            };
                                                            worksheet1.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet1.addRow([]);
                                                            const today = new Date();
                                                            const options: Intl.DateTimeFormatOptions = {
                                                                month: 'short',
                                                                day: 'numeric',
                                                                year: 'numeric'
                                                            };
                                                            const formattedDate = today.toLocaleDateString('en-US', options).toUpperCase();
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.getCell('C3').value = formattedDate;
                                                            worksheet1.getCell('C3').font = { bold: true, size: 14 };
                                                            worksheet1.getCell('D3').value = 'number increase';
                                                            worksheet1.getCell('E3').value = '% increase';
                                                            worksheet1.getCell('D3').font = { bold: true, size: 12, italic: true };
                                                            worksheet1.getCell('E3').font = { bold: true, size: 12, italic: true };

                                                            for (let i = 4; i <= 11; i++) {
                                                                worksheet1.getCell(`D${i}`).value = 0;
                                                            }
                                                            for (let i = 4; i <= 11; i++) {
                                                                worksheet1.getCell(`E${i}`).value = '0%';
                                                            }
                                                            worksheet1.getCell('B4').value = 'Number of factories';
                                                            worksheet1.getCell('B4').font = { bold: true, size: 14 };
                                                            worksheet1.getCell('B5').value = '# connected to WM';
                                                            worksheet1.getCell('B6').value = '# connected to BluWin';
                                                            worksheet1.getCell('B7').value = '# connected to TUV Rheinland';
                                                            worksheet1.getCell('B8').value = '# uploaded CIL in the last 3 months ';
                                                            worksheet1.getCell('B9').value = '# uploaded HIGG self report in the last 12 months';
                                                            worksheet1.getCell('B10').value = '# uploaded HIGG verified report in the last 24 months';
                                                            worksheet1.getCell('B11').value = '# uploaded wastewater test reports in the last 12 months ';
                                                            for (let i = 5; i <= 11; i++) {
                                                                worksheet1.getCell(`B${i}`).font = { size: 14 };
                                                            }
                                                            worksheet1.getCell('C4').value = walmart_overview_Data[0]['WalmartOverview.total_count'];
                                                            worksheet1.getCell('C5').value = walmart_overview_Data[0]['WalmartOverview.wm_connected'];
                                                            worksheet1.getCell('C6').value = walmart_overview_Data[0]['WalmartOverview.bw_connected'];
                                                            worksheet1.getCell('C7').value = walmart_overview_Data[0]['WalmartOverview.tuv_connected'];
                                                            worksheet1.getCell('C8').value = walmart_overview_Data[0]['WalmartOverview.cil_count'];
                                                            worksheet1.getCell('C9').value = walmart_overview_Data[0]['WalmartOverview.higg_self_count'];
                                                            worksheet1.getCell('C10').value = walmart_overview_Data[0]['WalmartOverview.higg_verified_count'];
                                                            worksheet1.getCell('C11').value = walmart_overview_Data[0]['WalmartOverview.ww_count'];
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.mergeCells('B16:G16');
                                                            worksheet1.getCell('B16').value = 'TUV Review Status';
                                                            worksheet1.getCell('B16').font = { bold: true, size: 16 };
                                                            worksheet1.getCell('B16').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'EBEEF1' },
                                                            };
                                                            worksheet1.getCell('B16').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet1.mergeCells('H16:I16');
                                                            worksheet1.getCell('H16').value = 'Summary';
                                                            worksheet1.getCell('H16').font = { bold: true, size: 16 };
                                                            worksheet1.getCell('H16').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'D6DCE4' },
                                                            };
                                                            worksheet1.getCell('H16').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            const columnHeaders1 = [
                                                                '',
                                                                'Total Number of factories',
                                                                'Number of factories reviewed by TUV ',
                                                                'Number of factories with Overall review status "Approved" ',
                                                                'Number of factories with Overall review status "Open Questions" ',
                                                                'Number of factories with Overall review status "Missing/Wrong Data" ',
                                                                'Number of factories with Overall review status "NA" ',
                                                                'Number of factories with all sections reviewed ',
                                                                'Number of factories with at least one section reviewed '
                                                            ];
                                                            const titleCells1 = worksheet1.addRow(columnHeaders1);
                                                            titleCells1.eachCell((cell) => {
                                                                cell.font = { bold: true, size: 12 };
                                                            });
                                                            worksheet1.getCell('A18').value = 'INPUT - CIL';
                                                            worksheet1.getCell('A19').value = 'PROCESS - HIGG';
                                                            worksheet1.getCell('A20').value = 'OUTPUT - WASTEWATER';
                                                            worksheet1.getCell('B18').value = walmart_overview_Data[0]['WalmartOverview.cil_count'];
                                                            worksheet1.getCell('B19').value = walmart_overview_Data[0]['WalmartOverview.higg_verified_count'];
                                                            worksheet1.getCell('B20').value = walmart_overview_Data[0]['WalmartOverview.ww_count'];
                                                            for (let i = 18; i <= 20; i++) {
                                                                worksheet1.getCell(`B${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('C18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === "CIL TUV review") ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === "CIL TUV review")["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('C19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV review') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV review')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('C20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV review') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV review')["TuvBluWinReview.count"] : 0;;
                                                            for (let i = 18; i <= 20; i++) {
                                                                worksheet1.getCell(`C${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('D18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Approved')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('D19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Approved')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('D20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Approved')["TuvBluWinReview.count"] : 0;
                                                            for (let i = 18; i <= 20; i++) {
                                                                worksheet1.getCell(`D${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('E18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Open questions')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('E19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Open questions')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('E20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Open questions')["TuvBluWinReview.count"] : 0;
                                                            for (let i = 18; i <= 20; i++) {
                                                                worksheet1.getCell(`E${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('F18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Data missing')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('F19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Data missing')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('F20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Data missing')["TuvBluWinReview.count"] : 0;
                                                            for (let i = 18; i <= 20; i++) {
                                                                worksheet1.getCell(`F${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('G18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-NA')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('G19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-NA')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('G20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-NA')["TuvBluWinReview.count"] : 0;
                                                            for (let i = 18; i <= 20; i++) {
                                                                worksheet1.getCell(`G${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.mergeCells('H18:H20');
                                                            worksheet1.getCell('H18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'TUV review all section') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'TUV review all section')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('H18').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'FFFF00' },
                                                            };
                                                            worksheet1.getCell('H18').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet1.mergeCells('I18:I20');
                                                            worksheet1.getCell('I18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'TUV review at least one section') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'TUV review at least one section')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('I18').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'FFFF00' },
                                                            };
                                                            worksheet1.getCell('I18').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.addRow([]);
                                                            worksheet1.mergeCells('B24:G24');
                                                            worksheet1.getCell('B24').value = 'BluWin Review Status ';
                                                            worksheet1.getCell('B24').font = { bold: true, size: 16 };
                                                            worksheet1.getCell('B24').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'EBEEF1' },
                                                            };
                                                            worksheet1.getCell('B24').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet1.mergeCells('H24:I24');
                                                            worksheet1.getCell('H24').value = 'Summary';
                                                            worksheet1.getCell('H24').font = { bold: true, size: 16 };
                                                            worksheet1.getCell('H24').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'D6DCE4' },
                                                            };
                                                            worksheet1.getCell('H24').alignment = { vertical: 'middle', horizontal: 'center' };


                                                            const columnHeaders2 = [
                                                                '',
                                                                'Total Number of factories',
                                                                'Number of factories reviewed by BluWin ',
                                                                'Number of factories with Overall review status "Approved" ',
                                                                'Number of factories with Overall review status "Open Questions" ',
                                                                'Number of factories with Overall review status "Missing/Wrong Data" ',
                                                                'Number of factories with Overall review status "NA" ',
                                                                'Number of factories with all sections reviewed ',
                                                                'Number of factories with at least one section reviewed '
                                                            ];
                                                            const titleCells2 = worksheet1.addRow(columnHeaders2);
                                                            titleCells2.eachCell((cell) => {
                                                                cell.font = { bold: true, size: 12 };
                                                            });

                                                            worksheet1.columns = [
                                                                { width: 20 },  // First column width
                                                                { width: 70 },  // Second column width
                                                                ...Array(worksheet1.columns.length - 2).fill({ width: 50 })  // Other columns width
                                                            ];
                                                            worksheet1.getCell('A26').value = 'INPUT - CIL';
                                                            worksheet1.getCell('A27').value = 'PROCESS - HIGG';
                                                            worksheet1.getCell('A28').value = 'OUTPUT - WASTEWATER';
                                                            worksheet1.getCell('B26').value = walmart_overview_Data[0]['WalmartOverview.cil_count'];
                                                            worksheet1.getCell('B27').value = walmart_overview_Data[0]['WalmartOverview.higg_verified_count'];
                                                            worksheet1.getCell('B28').value = walmart_overview_Data[0]['WalmartOverview.ww_count'];
                                                            for (let i = 26; i <= 28; i++) {
                                                                worksheet1.getCell(`B${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('C26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === "CIL BW review") ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === "CIL BW review")["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('C27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW review') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW review')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('C28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW review') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW review')["TuvBluWinReview.count"] : 0;;
                                                            for (let i = 26; i <= 28; i++) {
                                                                worksheet1.getCell(`C${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('D26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Approved')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('D27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Approved')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('D28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Approved')["TuvBluWinReview.count"] : 0;
                                                            for (let i = 26; i <= 28; i++) {
                                                                worksheet1.getCell(`D${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('E26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Open questions')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('E27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Open questions')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('E28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Open questions')["TuvBluWinReview.count"] : 0;
                                                            for (let i = 26; i <= 28; i++) {
                                                                worksheet1.getCell(`E${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('F26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Data missing')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('F27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Data missing')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('F28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Data missing')["TuvBluWinReview.count"] : 0;
                                                            for (let i = 26; i <= 28; i++) {
                                                                worksheet1.getCell(`F${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.getCell('G26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-NA')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('G27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-NA')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('G28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-NA')["TuvBluWinReview.count"] : 0;
                                                            for (let i = 26; i <= 28; i++) {
                                                                worksheet1.getCell(`G${i}`).fill = {
                                                                    type: 'pattern',
                                                                    pattern: 'solid',
                                                                    fgColor: { argb: 'FFFF00' },
                                                                };
                                                            }
                                                            worksheet1.mergeCells('H26:H28');
                                                            worksheet1.getCell('H26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'BW review all section') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'BW review all section')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('H26').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'FFFF00' },
                                                            };
                                                            worksheet1.getCell('H26').alignment = { vertical: 'middle', horizontal: 'center' };

                                                            worksheet1.mergeCells('I26:I28');
                                                            worksheet1.getCell('I26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'BW review at least one section') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'BW review at least one section')["TuvBluWinReview.count"] : 0;
                                                            worksheet1.getCell('I26').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'FFFF00' },
                                                            };
                                                            worksheet1.getCell('I26').alignment = { vertical: 'middle', horizontal: 'center' };

                                                            for (let i = 1; i <= 11; i++) {
                                                                worksheet1.getCell(`B${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 1; i <= 11; i++) {
                                                                worksheet1.getCell(`C${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 1; i <= 11; i++) {
                                                                worksheet1.getCell(`D${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 1; i <= 11; i++) {
                                                                worksheet1.getCell(`E${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 18; i <= 20; i++) {
                                                                worksheet1.getCell(`A${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 16; i <= 20; i++) {
                                                                worksheet1.getCell(`B${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 16; i <= 20; i++) {
                                                                worksheet1.getCell(`C${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 16; i <= 20; i++) {
                                                                worksheet1.getCell(`D${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 16; i <= 20; i++) {
                                                                worksheet1.getCell(`E${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 16; i <= 20; i++) {
                                                                worksheet1.getCell(`F${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 16; i <= 20; i++) {
                                                                worksheet1.getCell(`G${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 16; i <= 20; i++) {
                                                                worksheet1.getCell(`H${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 16; i <= 20; i++) {
                                                                worksheet1.getCell(`I${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 26; i <= 28; i++) {
                                                                worksheet1.getCell(`A${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 24; i <= 28; i++) {
                                                                worksheet1.getCell(`B${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 24; i <= 28; i++) {
                                                                worksheet1.getCell(`C${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 24; i <= 28; i++) {
                                                                worksheet1.getCell(`D${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 24; i <= 28; i++) {
                                                                worksheet1.getCell(`E${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 24; i <= 28; i++) {
                                                                worksheet1.getCell(`F${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 24; i <= 28; i++) {
                                                                worksheet1.getCell(`G${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 24; i <= 28; i++) {
                                                                worksheet1.getCell(`H${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }
                                                            for (let i = 24; i <= 28; i++) {
                                                                worksheet1.getCell(`I${i}`).border = {
                                                                    top: { style: 'thin' },
                                                                    left: { style: 'thin' },
                                                                    bottom: { style: 'thin' },
                                                                    right: { style: 'thin' }
                                                                };
                                                            }

                                                            const worksheet2 = workbook.addWorksheet('Details');
                                                            worksheet2.addRow([]);
                                                            worksheet2.addRow([]);
                                                            worksheet2.mergeCells('A1:K2');
                                                            worksheet2.getCell('A1').value = 'Factory Information';
                                                            worksheet2.getCell('A1').font = { bold: true, size: 14 };
                                                            worksheet2.getCell('A1').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'DBDBDB' },
                                                            };
                                                            worksheet2.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('L1:N2');
                                                            worksheet2.getCell('L1').value = 'Connection Status';
                                                            worksheet2.getCell('L1').font = { bold: true, size: 14 };
                                                            worksheet2.getCell('L1').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'ACB9CA' },
                                                            };
                                                            worksheet2.getCell('L1').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('O1:S1');
                                                            worksheet2.getCell('O1').value = 'INPUT';
                                                            worksheet2.getCell('O1').font = { bold: true, size: 14 };
                                                            worksheet2.getCell('O1').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: '92D050' },
                                                            };
                                                            worksheet2.getCell('O1').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('T1:Y1');
                                                            worksheet2.getCell('T1').value = 'PROCESS';
                                                            worksheet2.getCell('T1').font = { bold: true, size: 14 };
                                                            worksheet2.getCell('T1').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'FFC000' },
                                                            };
                                                            worksheet2.getCell('T1').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('Z1:AD1');
                                                            worksheet2.getCell('Z1').value = 'OUTPUT';
                                                            worksheet2.getCell('Z1').font = { bold: true, size: 14 };
                                                            worksheet2.getCell('Z1').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: '5B9BD5' },
                                                            };
                                                            worksheet2.getCell('Z1').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('AE1:AH1');
                                                            worksheet2.getCell('AE1').value = 'Summary Review Status';
                                                            worksheet2.getCell('AE1').font = { bold: true, size: 14 };
                                                            worksheet2.getCell('AE1').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'ACB9CA' },
                                                            };
                                                            worksheet2.getCell('AE1').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('AI1:AL2');
                                                            worksheet2.getCell('AI1').value = 'RECOMMENDATION COMMENTS ';
                                                            worksheet2.getCell('AI1').font = { bold: true, size: 14 };
                                                            worksheet2.getCell('AI1').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'C6E0B4' },
                                                            };
                                                            worksheet2.getCell('AI1').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.getCell('O2').value = 'FACTORY ACTIVITY';
                                                            worksheet2.getCell('O2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('O2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'E2EFDA' },
                                                            };
                                                            worksheet2.getCell('O2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('P2:Q2');
                                                            worksheet2.getCell('P2').value = 'TUV REVIEW STATUS';
                                                            worksheet2.getCell('P2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('P2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'E2EFDA' },
                                                            };
                                                            worksheet2.getCell('P2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('R2:S2');
                                                            worksheet2.getCell('R2').value = 'BLUWIN REVIEW STATUS';
                                                            worksheet2.getCell('R2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('R2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'E2EFDA' },
                                                            };
                                                            worksheet2.getCell('R2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('T2:U2');
                                                            worksheet2.getCell('T2').value = 'FACTORY ACTIVITY';
                                                            worksheet2.getCell('T2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('T2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'FFF2CC' },
                                                            };
                                                            worksheet2.getCell('T2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('V2:W2');
                                                            worksheet2.getCell('V2').value = 'TUV REVIEW STATUS';
                                                            worksheet2.getCell('V2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('V2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'FFF2CC' },
                                                            };
                                                            worksheet2.getCell('V2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('X2:Y2');
                                                            worksheet2.getCell('X2').value = 'BLUWIN REVIEW STATUS';
                                                            worksheet2.getCell('X2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('X2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'FFF2CC' },
                                                            };
                                                            worksheet2.getCell('X2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.getCell('Z2').value = 'FACTORY ACTIVITY';
                                                            worksheet2.getCell('Z2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('Z2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'DDEBF7' },
                                                            };
                                                            worksheet2.getCell('Z2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('AA2:AB2');
                                                            worksheet2.getCell('AA2').value = 'TUV REVIEW STATUS';
                                                            worksheet2.getCell('AA2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('AA2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'DDEBF7' },
                                                            };
                                                            worksheet2.getCell('AA2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('AC2:AD2');
                                                            worksheet2.getCell('AC2').value = 'BLUWIN REVIEW STATUS';
                                                            worksheet2.getCell('AC2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('AC2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'DDEBF7' },
                                                            };
                                                            worksheet2.getCell('AC2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('AE2:AF2');
                                                            worksheet2.getCell('AE2').value = 'TUV';
                                                            worksheet2.getCell('AE2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('AE2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'D6DCE4' },
                                                            };
                                                            worksheet2.getCell('AE2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.mergeCells('AG2:AH2');
                                                            worksheet2.getCell('AG2').value = 'BluWin';
                                                            worksheet2.getCell('AG2').font = { bold: true, size: 12 };
                                                            worksheet2.getCell('AG2').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'D6DCE4' },
                                                            };
                                                            worksheet2.getCell('AG2').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            wm_tuv_bluwin_Data.forEach((d: any) => {
                                                                delete d['WmTuvBluWinDetails.disabled_account'];
                                                            });
                                                            const columnHeaders = [
                                                                'ORG_ID',
                                                                'Vendor Number',
                                                                'Mill ID',
                                                                'Vendor Name',
                                                                'Account Type',
                                                                'Factory Name',
                                                                'Factory Type',
                                                                'Country',
                                                                'Registration Date',
                                                                'Username',
                                                                'Email',
                                                                'Connected To Walmart',
                                                                'Connected To TUV Rheinland',
                                                                'Connected To BluWin',
                                                                'Uploaded Cil in the last 3 month',
                                                                'CIL Check TUV',
                                                                'CIL overall Evaluation TUV',
                                                                'CIL Check BluWin',
                                                                'CIL overall Evaluation BluWin',
                                                                'Uploaded Higg FEM self report in last 12 months',
                                                                'Uploaded Higg FEM verified report in last 24 months',
                                                                'HIGG Checked TUV',
                                                                'HIGG overall Evaluation TUV',
                                                                'HIGG Checked BluWin',
                                                                'HIGG overall Evaluation BluWin',
                                                                'Uploaded Wastewater test reports in the last 12 months',
                                                                'Wastewater Checked TUV',
                                                                'Wastewater overall Evaluation TUV',
                                                                'Wastewater Checked BluWin',
                                                                'Wastewater overall Evaluation BluWin',
                                                                'All sections reviewed',
                                                                'At Least One Section Reviewed',
                                                                'All sections Reviewed',
                                                                'At Least One section Reviewed'
                                                            ];
                                                            const titleCells = worksheet2.addRow(columnHeaders);
                                                            titleCells.eachCell((cell) => {
                                                                cell.font = { bold: true, size: 12 };
                                                            });

                                                            const columnWidth = 50;
                                                            worksheet2.columns.forEach(column => {
                                                                column.width = columnWidth;
                                                            });
                                                            worksheet2.getCell('AI3').value = 'Service Recommended';
                                                            worksheet2.getCell('AI3').font = { bold: true };
                                                            worksheet2.getCell('AI3').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'E2EFDA' },
                                                            };
                                                            worksheet2.getCell('AI3').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.getCell('AJ3').value = 'Date of Recommendation';
                                                            worksheet2.getCell('AJ3').font = { bold: true };
                                                            worksheet2.getCell('AJ3').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'E2EFDA' },
                                                            };
                                                            worksheet2.getCell('AJ3').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.getCell('AK3').value = 'Name of Recommender';
                                                            worksheet2.getCell('AK3').font = { bold: true };
                                                            worksheet2.getCell('AK3').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'E2EFDA' },
                                                            };
                                                            worksheet2.getCell('AK3').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            worksheet2.getCell('AL3').value = 'Other Notes';
                                                            worksheet2.getCell('AL3').font = { bold: true };
                                                            worksheet2.getCell('AL3').fill = {
                                                                type: 'pattern',
                                                                pattern: 'solid',
                                                                fgColor: { argb: 'E2EFDA' },
                                                            };
                                                            worksheet2.getCell('AL3').alignment = { vertical: 'middle', horizontal: 'center' };
                                                            wm_tuv_bluwin_Data.forEach((d: any) => {
                                                                worksheet2.addRow(Object.values(d));
                                                            });
                                                            worksheet2.eachRow((row, rowNumber) => {
                                                                row.eachCell((cell: any) => {
                                                                    const value = cell.value.toString();
                                                                    if (value === 'Yes' || value === 'Approved') {
                                                                        cell.fill = {
                                                                            type: 'pattern',
                                                                            pattern: 'solid',
                                                                            fgColor: { argb: 'C6EFCE' }, // Green
                                                                        };
                                                                        cell.font = {
                                                                            color: { argb: '116100' } // Dark Green text color
                                                                        };
                                                                    } else if (value === 'No' || value === 'Data missing') {
                                                                        cell.fill = {
                                                                            type: 'pattern',
                                                                            pattern: 'solid',
                                                                            fgColor: { argb: 'FCC7CD' }, // Red
                                                                        };
                                                                        cell.font = {
                                                                            color: { argb: 'A22014' } // Dark Red text color
                                                                        };
                                                                    }
                                                                    else if (value === 'No' || value === 'Open questions') {
                                                                        cell.fill = {
                                                                            type: 'pattern',
                                                                            pattern: 'solid',
                                                                            fgColor: { argb: 'FDEA9B' }, // Red
                                                                        };
                                                                        cell.font = {
                                                                            color: { argb: 'A5640D' } // Dark Red text color
                                                                        };
                                                                    }
                                                                });

                                                            })
                                                            workbook.xlsx.writeBuffer().then((data) => {
                                                                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                                                                this.uploadWalmartReport(blob, 'Walmart_Report_v2.xlsx')?.subscribe(
                                                                    (res: any) => {
                                                                        console.log('File uploaded successfully', res);

                                                                        // Now download the report after the upload is successful
                                                                        this.downloadWalmartReport();
                                                                    },
                                                                    (error: any) => {
                                                                        console.error('Error uploading file', error);
                                                                    }
                                                                );

                                                            }).catch((error) => {
                                                                console.error('Error generating Excel file', error);
                                                            });

                                                        }
                                                        else {
                                                            wm_tuv_bluwin_Data.forEach((d: any) => {
                                                                delete d['WmTuvBluWinDetails.disabled_account'];
                                                            });
                                                            this.downloadAndEditWalmartReport(walmart_overview_Data, tuv_bluwin_Data, wm_tuv_bluwin_Data);
                                                        }
                                                    })
                                            })
                                    }

                                    else {
                                        data.result = this.translateColumns(data.result, [], queryObj);
                                        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.result.data);
                                        const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                        XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                        XLSX.writeFile(myworkbook, queryObj.Name + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
                                    }

                                }
                            })
                    }
                    break;
                default:
                    break;
            }
        }
        else {
            if (dataPopUp) {
                const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataPopUp);
                const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                XLSX.writeFile(myworkbook, 'test' + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
            }
        }
    }
    getNewQueryObject(queryId: number): Observable<DashboardQueryInterface> {
        return this.http.get(this.baseHref + '/getQueryObject/' + queryId)
            .pipe(map((res: any) => {
                return {
                    Id: res['queries'][0]['id'],
                    Name: res['queries'][0]['name'],
                    Description: res['queries'][0]['description'],
                    QueryJSON: res['queries'][0]['query_json'],
                    Active: res['queries'][0]['active'],
                    VisualType: res['queries'][0]['default_visual_type_id'],
                    NeedsCompliance: res['queries'][0]['needs_compliance'],
                    RawQueryId: res['queries'][0]['raw_query_id'],
                    DrillQueryId: res['queries'][0]['drill_query_id'],
                    HasTarget: res['queries'][0]['has_target'],
                    CanCompare: res['queries'][0]['can_compare'],
                    GetLatestChemicals: res['queries'][0]['get_latest_chemicals'],
                    FilterConfig: JSON.parse(res['queries'][0]['filter_config']),
                    Configs: res['queries'][0]['configs'].map((el: any) => ({
                        Code: el['Code'],
                        QueryPivotConfig: JSON.parse(el['QueryPivotConfig']),
                        Groupings: JSON.parse(el['Groupings']),
                        InteractionConfig: JSON.parse(el['InteractionConfig'])
                    }))
                };
            }));
    }
    private prepareCubeJsFilters(filters: Array<any>, query: DashboardQueryInterface): Array<Object> {

        const replacedFilters = filters.map(filter => {
            const { meta, ...modifiedObject } = filter;
            return this.replaceKeys(modifiedObject, FilterKeys)
        });
        let newFilters: Array<any> = [];
        replacedFilters.forEach((f) => {
            const objKeyName: string = Object.keys(f)[0];
            const alternativeName = f.hasOwnProperty('meta') ? f['meta' as keyof typeof f] : objKeyName;
            if (query.FilterConfig) {
                const cubeName = query.FilterConfig.hasOwnProperty(objKeyName) ? query.FilterConfig[objKeyName as keyof typeof query.FilterConfig] : alternativeName;
                const objValue = f[objKeyName as keyof typeof f];
                if (query.FilterConfig[objKeyName]) {
                    if (objKeyName.includes('date') && Object.keys(query.FilterConfig).some(value => value.toLowerCase().includes('date'))) {
                        if (cubeName.includes('BrandActivityWithCompliance.year')) {
                            let std = new Date(objValue[0]);
                            let edd = new Date(objValue[1]);
                            let years = [];
                            for (let year = std.getFullYear(); year <= edd.getFullYear(); year++) {
                                years.push(year.toString());
                            }
                            newFilters.push({
                                "member": cubeName,
                                "operator": "equals",
                                "values": years
                            })
                        }
                        else {
                            newFilters.push({
                                "member": cubeName,
                                "operator": "inDateRange",
                                "values": objValue
                            })
                        }
                    }
                    else {
                        if (objKeyName.includes('fabricRollId') && 'fabricRollId' in query.FilterConfig) {
                            newFilters.push({
                                "member": cubeName,
                                "operator": "contains",
                                "values": [objValue]
                            })

                        }
                        else {
                            if (objKeyName.includes('identification_supplier_id') && 'identification_supplier_id' in query.FilterConfig) {
                                if (query.FilterConfig['identification_supplier_id'] == 'SupplierIdentification.identification_supplier_id') {
                                    newFilters.push(
                                        {
                                            "member": "SupplierIdentification.identification_name",
                                            "operator": "equals",
                                            "values": ['SCOPE']
                                        },
                                        {
                                            "member": cubeName,
                                            "operator": "equals",
                                            "values": objValue
                                        },
                                        {
                                            "member": "SupplierIdentification.brand_id",
                                            "operator": "equals",
                                            "values": [this.brandid?.toString()]
                                        },
                                    )
                                }
                                else {
                                    newFilters.push({
                                        "member": cubeName,
                                        "operator": "equals",
                                        "values": objValue
                                    })
                                }

                            }
                            else {
                                if (objKeyName.includes('usedFor') && 'usedFor' in query.FilterConfig) {
                                    if (query.FilterConfig['usedFor'] == 'UsedFor.brand_name') {
                                        newFilters.push({
                                            "member": "UsedFor.used_for_brand_id",
                                            "operator": "equals",
                                            "values": [this.brandid?.toString()],

                                        },
                                            {
                                                "member": cubeName,
                                                "operator": "equals",
                                                "values": objValue
                                            }
                                        )
                                    }
                                    else {
                                        newFilters.push({
                                            "member": "Activity.used_for_brand",
                                            "operator": "equals",
                                            "values": [this.brandid?.toString()],

                                        })
                                    }
                                }

                                else {
                                    if (!objKeyName.includes('date'))
                                        newFilters.push({
                                            "member": cubeName,
                                            "operator": "equals",
                                            "values": objValue
                                        })
                                }
                            }
                        }
                    }
                }
            }
        })
        return newFilters;

    }

    private transformData(pivotConfig: Object, visualType: string, data: ResultSet<any>, queryObj: DashboardQueryInterface) {
        let transformedData: any = [];
        switch (visualType) {
            case VisualType.BarChart: {
                transformedData = this.transformToGroupBarChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.GroupBarChart: {
                transformedData = this.transformToGroupBarChart(pivotConfig, data, queryObj)

                break;
            }
            case VisualType.StackedBarChart: {
                transformedData = this.transformToGroupBarChart(pivotConfig, data, queryObj)

                break;
            }
            case VisualType.LineChart: {
                transformedData = this.transformToLineChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.MultilineChart: {
                transformedData = this.transformToMultiLineChart(pivotConfig, data, queryObj)

                break;
            }
            case VisualType.PieChart: {
                transformedData = this.transformToPieChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.Gauge: {
                transformedData = this.transformToGaugeChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.WorldMap: {
                transformedData = this.transformToWorldMapChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.Table: {
                transformedData = this.transformToTableChart(pivotConfig, data, queryObj)
                    .then(result => {
                        return result;
                    })
                    .catch(error => {
                        console.error("Error transforming data:", error);
                        return [];
                    });
                break;
            }
            case VisualType.NumberCard: {
                transformedData = this.transformToNumberChart(pivotConfig, data, queryObj);
                break;
            }
            case VisualType.AreaChart: {
                transformedData = this.transformToAreaChart(pivotConfig, data, queryObj);
                break;
            }
            default:
                transformedData = data;
                break;
        }
        return transformedData;
    }

    private transformToGroupBarChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        // Extract unique titles from the series
        const uniqueTitles: string[] = data.series(pivotConfig).map((s: any) => s.title.split(',')[0]).filter((title: string, index: number, self: string[]) => self.indexOf(title) === index);

        // Create a map to store the stack levels for each unique title
        const stackLevels: { [title: string]: number } = {};
        uniqueTitles.forEach((title: string, index: number) => {
            stackLevels[title] = index;
        });
        let result: Object = {
            chartType: 'bar',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => {
                let label = c.x;
                let shortLabel: any;
                if (this.isValidISODateTime(label))
                    shortLabel = label.slice(0, 10);
                else {
                    if (label.length > 15) {
                        shortLabel = label.substring(0, 15) + '...';
                    }
                    else shortLabel = label;
                }
                const granularity = data.loadResponse.results[0].query.timeDimensions[0]?.granularity;
                if (granularity === 'quarter') {
                    const date = new Date(label);
                    const quarter = Math.floor((date.getMonth() / 3) + 1);
                    const year = date.getFullYear();
                    return { short: `${year}-Q${quarter}`, long: `${year}-Q${quarter}` };
                } else {
                    // Return the original label if granularity is not 'quarter'
                    return { short: shortLabel, long: label };
                }


            }),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.getColorBySeriesName(s.key);
                const stack = stackLevels[s.key.split(',')[0]] || null;
                return stack ? {
                    label: this.findTranslation(s.ke, queryObj.Id),
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    data: s.series.map((r: { value: any; }) => Number(r.value?.toFixed(1))),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues),
                    stack: stack
                } : {
                    label: this.findTranslation(s.key, queryObj.Id),
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    data: s.series.map((r: { value: any; }) => Number(r.value?.toFixed(1))),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                };

            })
        };
        return result;
    }

    private transformToLineChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {

        let result: Object = {
            chartType: 'line',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => c.x),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.getColorBySeriesName(s.key);
                return {
                    label: s.title,
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    data: s.series.map((r: { value: any; }) => r.value),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                }
            })
        };
        return result;
    }

    private transformToMultiLineChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let result: Record<string, any> = {
            chartType: 'bar',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => {
                let label = c.x;
                let shortLabel = label;

                if (label.length > 15) {
                    shortLabel = label.substring(0, 15) + '...';
                }

                // Return an array with both the short and long labels
                return { short: shortLabel, long: label }
            }),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.getColorBySeriesName(s.key);
                return {
                    label: s.title,
                    data: s.series.map((r: { value: any; }) => r.value),
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    pointBackgroundColor: color[index % color.length],
                    pointBorderColor: color[index % color.length],
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    // xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                }
            })
        };
        result['chartData'][0].order = 1;
        result['chartData'][1].order = 0;
        result['chartData'][1].type = 'line';
        if (result['chartData'].length > 2) {
            result['chartData'][3].order = 0;
            result['chartData'][3].type = 'line';
        }
        return result;
    }

    private async transformToPieChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let transformedData: any[];
        if (this.isSpecialQuery(queryObj)) {
            transformedData = await this.specialQueries(queryObj, data.tablePivot()) as any[];
        } else {
            transformedData = await this.prepareDataForPieChart(data.series(pivotConfig), queryObj);
        }


        let datasets = [];
        let allLabels: any[] = [];

        if (transformedData.some((s: any) => Array.isArray(s.value))) {
            // Handle nested data by creating a separate dataset for each date or category
            transformedData.forEach((item: any) => {
                let labels = item.value.map((v: any) => v.label);
                let values = item.value.map((v: any) => v.value);

                // Collect all labels to ensure colors match across datasets
                allLabels = allLabels.concat(labels.filter((label: any) => !allLabels.includes(label)));

                datasets.push({
                    label: item.label,
                    backgroundColor: labels.map((key: any, index: number) => { const color = this.getColorBySeriesName(key); return color[index % color.length] }),
                    // borderColor: labels.map((key: any, index: number) => { const color = this.getColorBySeriesName(key); return color[index % color.length] }),
                    // backgroundColor: labels.map((label:any) => this.getColorBySeriesName(label)),
                    data: values
                });
            });
        } else {
            // Handle flat data by creating a single dataset
            let labels = transformedData.map((s: any) => s.label);
            let values = transformedData.map((s: any) => s.value);
            allLabels = labels;

            datasets.push({
                label: queryObj.Name,
                backgroundColor: labels.map((key: any, index: number) => { const color = this.getColorBySeriesName(key); return color[index % color.length] }),
                borderColor: labels.map((key: any, index: number) => { const color = this.getColorBySeriesName(key); return color[index % color.length] }),
                data: values
            });
        }

        let result = {
            chartType: 'pie',
            chartLabels: allLabels.map(label => {
                let shortLabel = label.length > 15 ? label.substring(0, 15) + '...' : label;
                return { short: shortLabel, long: label };
            }),
            chartData: datasets
        };

        return result;
    }

    private prepareDataForPieChart(chartData: any, queryObj: any) {
        const transformedArray: { label: string; value: number }[] = [];
        chartData.forEach((item: any) => {
            if (item.series) {
                item.series.forEach((serie: any) => {
                    const label = serie.x == "" ? this.findTranslation(item.key, queryObj.Id) : this.findTranslation(serie.x, queryObj.Id);
                    if (label != undefined)
                        transformedArray.push({ label, value: serie.value });
                });
            } else {
                // Handle flat data case
                transformedArray.push({ label: item.label, value: item.value });
            }
        });
        return transformedArray;
    }



    private transformToGaugeChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {

        let result: Object = {
            chartType: 'doughnut',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => c.x),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.getColorBySeriesName(s.key);
                return {
                    label: s.title,
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    data: s.series.map((r: { value: any; }) => r.value),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                }
            })
        };
        return result;
    }

    private transformToWorldMapChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let result: any = {
            chartType: 'map',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => {
                let label = c.x;
                let shortLabel = label.length > 15 ? label.substring(0, 15) + '...' : label;

                // Return an array with both the short and long labels
                return { short: shortLabel, long: label };
            }),
            chartData: {}
        };

        let aggData = this.isSpecialQuery(queryObj) ? this.specialQueries(queryObj, data.tablePivot()) : data.chartPivot(pivotConfig);

        result.chartData = aggData;

        return result;
    }


    private async transformToTableChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let result: any;
        if (this.isSpecialQuery(queryObj)) {
            result = await this.specialQueries(queryObj, data.tablePivot(pivotConfig));
        } else {
            result = data.tablePivot(pivotConfig);
        }
        return result;
    }

    private transformToNumberChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {

        let numericValues = data
            .seriesNames()
            .map((s: any) => {
                return {
                    numericValues: data.totalRow()[s.key],
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    xValue: data.totalRow().x

                }
            })
        return numericValues;
    }
    private transformToAreaChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let result: Object = {
            chartType: 'line',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => {
                let label = c.x;
                let shortLabel = label;

                if (label.length > 15) {
                    shortLabel = label.substring(0, 15) + '...';
                }

                // Return an array with both the short and long labels
                return { short: shortLabel, long: label }
            }),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.generateRandomColors();
                return {
                    label: s.title,
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    pointBackgroundColor: color[index % color.length],
                    pointBorderColor: color[index % color.length],
                    fill: true,
                    data: s.series.map((r: { value: any; }) => r.value),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    // xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                }
            })
        };
        return result;
    }

    calculateFontSize(width: number | undefined, height: number | undefined, dimension: number, max: number, min: number, perOrpx: string) {
        if (width && height) {
            const minDimension = Math.min(width, height);
            const basePercentage = minDimension * dimension;

            const maxPercentage = max;
            const minPercentage = min;

            let fontSizePercentage = basePercentage;

            if (fontSizePercentage > maxPercentage) {
                fontSizePercentage = maxPercentage;
                return `${fontSizePercentage}` + perOrpx
            }
            else if (fontSizePercentage < minPercentage) {
                fontSizePercentage = minPercentage;
                return `${fontSizePercentage}` + perOrpx
            }

            return `${fontSizePercentage}px`;
        } else {
            return '100%';
        }
    }
    private getColorBySeriesName(seriesName: string): Array<string> {
        const matchingSerieName = Object.keys(SeriesColorMap).find(key => seriesName.includes(key)) || '';
        return SeriesColorMap[matchingSerieName] || ['gray']; // Default color for unknown series names
    }
    private generateRandomColors() {
        let color = '';
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        color = `rgba(${r},${g},${b}, 0.6)`;
        return color;
    }
    // TODO:Generilize these replase keys funkctions. Function to be modified are replaseKeys, translateColumns, findTranslateKey, changeKey. There some things in common between these functions and there must be found a way how to unify/refactor them.
    replaceKeys(original: Array<any>, keyMapping: any): Array<any> {
        return Object.entries(original).reduce((result, [key, value]) => {
            const newKey = keyMapping[key as keyof typeof keyMapping] || key;
            result[newKey] = value;
            return result;
        }, {} as Array<any>);
    }
    //translates the columns of the table and fill the table with the new columns and data
    translateColumns(data: any, tableColumns: any, queryObj?: DashboardQueryInterface) {
        let queryId = queryObj ? queryObj.Id : 0;
        let columnsConfig: any = queryObj?.Configs.find(el => el.Code === VisualType.Table)!.InteractionConfig;
        tableColumns = Object.keys(Object.assign({}, ...data))
            .filter(column => !this.checkColumnProperty(columnsConfig, column, 'hidden', true)) // Filter out hidden columns
            .map(column => {
                // Check if there is a translation for the column, otherwise use the original column name
                let translatedColumn = this.findTranslation(column, queryId) != column ? this.findTranslation(column, queryId) : undefined;
                if (!translatedColumn) {
                    for (let keyword of this.translations.map((item: any) => item.keyword)) {
                        if (column.includes(keyword)) {
                            if (column.includes('Higg.year_self_assessment_date') || column.includes('Higg.year_verf_assessment_date') || column.includes('WasteWaterCombined.latest_date') || column.includes('WasteWaterCombined.latest_source'))
                                translatedColumn = column.split(',')[0].trim() != '' ? column.split(',')[0].trim() + ' ' + this.findTranslation(keyword, queryId) || column : this.findTranslation(keyword, queryId) || column

                            else
                                translatedColumn = this.findTranslation(keyword, queryId) || column;
                            break;
                        }
                        else {
                            translatedColumn = column;
                        }
                    }
                }
                return { key: column, name: translatedColumn };

            });
        const datasource = data.map((row: any) => {
            // First, copy all existing data fields to the newRow
            const newRow: any = { ...row };

            // Then, map specified keys to new names and overwrite or add new keys in newRow
            tableColumns.forEach((column: any) => {
                if (column.key in row) {
                    newRow[column.name] = row[column.key]; // Map original key to new key
                    if (column.name !== column.key) {
                        delete newRow[column.key]; // Optionally delete the original key
                    }
                }
            });

            return newRow;
        });

        let clickableColumns = tableColumns.filter((column: any) => this.checkColumnProperty(columnsConfig, column.key, 'clickable', true))
            .map((column: any) => {
                return { key: column.key, name: column.name, action: columnsConfig?.columns[column.key].action, payload: columnsConfig?.columns[column.key].payload, condition: columnsConfig?.columns[column.key].condition }
            });

        return { columns: tableColumns, data: datasource, clickableColumns: clickableColumns }
    }

    checkColumnProperty(columnsConfig: unknown, columnName: string, propertyName: string, propertyValue: any): boolean {
        // First, check if columnsConfig is an object
        if (columnsConfig !== null && typeof columnsConfig === 'object') {
            // Cast to any temporarily to access potential properties
            const config = columnsConfig as any;
            // Check if 'columns' exists and is an object
            if (config.columns && typeof config.columns === 'object') {
                // Check if the specified columnName exists within columns
                if (config.columns[columnName] && typeof config.columns[columnName] === 'object') {
                    // Finally, check if 'hidden' exists as a boolean within the column
                    const column = config.columns[columnName];
                    return propertyName in column && column[propertyName] === propertyValue;
                }
            }
        }
        return false;
    }

    findTranslation(translationValue: string, queryId: any): string | undefined {
        let translationData = this.translations.filter((item: any) => item.keyword === translationValue);

        if (translationData.length > 0) {
            if (translationData.length === 1) {
                return translationData[0].translation;
            }

            let translationDataWithQueryId = translationData.filter((item: any) => item.query_id === queryId);

            if (translationDataWithQueryId.length > 0) {
                return translationDataWithQueryId[0].translation;
            }

            return translationData[0].translation;
        }

        return translationValue;
    }

    latestFormulas = []
    private getFormulasFirst(query: any) {
        let chemicalQuery = {
            "dimensions": [
                "Formulas.organization_id",
                "Formulas.chemical_identifier"
            ],
            "timeDimensions": [
                {
                    "dimension": "Formulas.scan_date"
                }
            ],
            "order": [
                [
                    "Formulas.chemical_identifier",
                    "asc"
                ]
            ],
            "filters": [...query.filters],
            "measures": [
                "Formulas.latest_formula_in_range"
            ]
        };
        return this.getCubeJSData(chemicalQuery, undefined, undefined, true, false)
            .then(data => {
                let tempData = data.chartPivot();
                tempData = this.changeKey("Formulas.latest_formula_in_range", "latest_formula", tempData);
                this.latestFormulas = tempData.map(function (el: any) { return el.latest_formula; });
                let latestChemicalsIds: any[] = [];
                let newFilters;
                for (let obj of this.latestFormulas as any[]) {
                    latestChemicalsIds.push(obj.toString());
                }
                newFilters = [
                    {
                        "and": latestChemicalsIds.length == 0 ? [
                            ...query.filters,

                        ] : [
                            ...query.filters,
                            {
                                "member": 'Formulas.id',
                                "operator": 'equals',
                                "values": latestChemicalsIds
                            }
                        ]
                    }
                ]
                query.filters = newFilters;
                return query;
            })
    }
    private getConnectedFactories() {
        let chemicalQuery = {
            "dimensions": [
                "Factories.factory_name",
                "Factories.organization_id"
            ],
            "timeDimensions": [
            ],
            "order": [
            ],
            "filters": [],
            "measures": [
            ]
        };
        return this.getCubeJSData(chemicalQuery, undefined, undefined, true, false)
            .then(data => {
                let tempData = data['loadResponse']['results'][0]['data'];
                tempData = this.changeKey("Factories.factory_name", "factory_name", tempData);
                tempData = this.changeKey("Factories.organization_id", "organization_id", tempData);
                return tempData;
            })
    }

    private  specialQueries(queryObj: DashboardQueryInterface, data: any) {
        switch (queryObj.Name) {
            case "Supplier count by compliance rate": {
                let querterData = this.groupDataByQuarter(data)

                const quarterlyCounts: any[] = [];

                for (const quarter in querterData) {
                    if (Object.prototype.hasOwnProperty.call(querterData, quarter)) {
                        let under50PercentCount = 0;
                        let between50And75PercentCount = 0;
                        let over75PercentCount = 0;

                        querterData[quarter].forEach((entry: any) => {
                            const compliancePct = entry["factory_compliant_pct"];
                            if (compliancePct < 50) {
                                under50PercentCount++;
                            } else if (compliancePct >= 50 && compliancePct <= 75) {
                                between50And75PercentCount++;
                            } else {
                                over75PercentCount++;
                            }
                        });
                        const date = new Date(quarter);
                        const quarter_ = Math.floor((date.getMonth() / 3) + 1);
                        const year = date.getFullYear();

                        quarterlyCounts.push({
                            label: `${year}-Q${quarter_}`,
                            value: [
                                { label: "under 50%", value: under50PercentCount },
                                { label: "50%-75%", value: between50And75PercentCount },
                                { label: "over 75%", value: over75PercentCount }
                            ]
                        });
                    }
                }

                return quarterlyCounts;
            };
            case "Country Performance": {
                const result: { country: any; count: number, quarters: any }[] = [];

                // Loop through the data
                data.forEach((entry: any) => {
                    const complianceValue = parseInt(entry["Formulas.factory_compliant_pct"]); // Convert compliance to integer
                    const countryName = entry["Formulas.country"].toUpperCase();
                    const quarter = entry["Formulas.scan_date.quarter"]; // Assume there is a quarter field in your data
                    const date = new Date(quarter);
                    const quarter_ = Math.floor((date.getMonth() / 3) + 1);
                    const year = date.getFullYear();
                    const quarterLabel = `${year}-Q${quarter_}`;
                    // Check if country exists in the result array
                    const existingCountryIndex = result.findIndex(item => item.country === countryName);
                    if (existingCountryIndex === -1) {
                        // If country doesn't exist in the result, add it with initial quarter data
                        result.push({
                            country: countryName,
                            count: 1,
                            quarters: {
                                [quarterLabel]: {
                                    min: complianceValue,
                                    max: complianceValue,
                                    avg: complianceValue,
                                    count: 1
                                }
                            }
                        });
                    } else {
                        // Country exists, check if the quarter exists for this country
                        let country = result[existingCountryIndex];
                        country.count += 1;
                        if (!country.quarters[quarterLabel]) {
                            // If the quarter doesn't exist, initialize it
                            country.quarters[quarterLabel] = {
                                min: complianceValue,
                                max: complianceValue,
                                avg: complianceValue,
                                count: 1
                            };
                        } else {
                            // Update min, max, and sum for existing quarter
                            let quarterData = country.quarters[quarterLabel];
                            quarterData.min = Math.min(quarterData.min, complianceValue);
                            quarterData.max = Math.max(quarterData.max, complianceValue);
                            quarterData.count += 1;
                            quarterData.avg = (quarterData.avg * (quarterData.count - 1) + complianceValue) / quarterData.count;
                            quarterData.avg = Number(quarterData.avg.toFixed(2)); // Recalculate average and format to two decimal places
                        }
                    }
                });

                return result;
            };
            case "Quarterly Inventory Overview": case "Monthly Inventory Overview": {
                return new Promise((resolve, reject) => {
                    const allObservables = forkJoin([
                        this.getConnectedFactories()
                    ]);

                    allObservables.subscribe(responses => {
                        let connectedFactories = responses[0];
                        let factoryNamesInData = data.map((obj: any) => obj['Formulas.organization_name']);

                        if (queryObj.Name == "Monthly Inventory Overview") {
                            const allObservables = forkJoin([
                                this.getCubeJSData(JSON.parse(queryObj.QueryJSON), undefined),
                                this.http.get<any>(this.baseHref + '/getAnalyticsInternalComments')
                            ]);

                            allObservables.subscribe(responses => {
                                let comments = responses[1]; 
                                data = data.map((obj: any) => { 
                                    obj['Formulas.scanned'] = (connectedFactories.map((factory: any) => factory.factory_name)).includes(obj['Formulas.organization_name']) ? 'Yes' : 'No';
                                    obj.hasComment = false;
                                    obj.comment = {
                                        supplierId: obj['Formulas.organization_id'],
                                        supplierName: obj['Formulas.organization_name'],
                                        commentDetails: [],
                                        queryId: queryObj.Id
                                    };
                                    return this.reorderProperties(obj, 'Formulas.scanned');
                                });
                                connectedFactories.forEach((factory: any) => {
                                    if (!factoryNamesInData.includes(factory.factory_name)) {
                                        let newEntry: any = {
                                            'Formulas.organization_name': factory.factory_name,
                                            'Formulas.scanned': 'No',
                                            'Formulas.organization_id': factory.organization_id,
                                            hasComment: false,
                                            comment: {
                                                supplierId: factory.organization_id,
                                                supplierName: factory.factory_name,
                                                commentDetails: [],
                                                queryId: queryObj.Id
                                            }
                                        };
                                        for (let key in data[0]) {
                                            if (key !== 'Formulas.organization_name' && key !== 'Formulas.scanned' && key !== 'Formulas.organization_id' && key !== 'hasComment' && key !== 'comment') {
                                                newEntry[key] = '';
                                            }
                                        }
                                        data.push(newEntry);
                                    }
                                });
                                if (comments.length !== 0) {
                                    comments.forEach((comm: any) => {
                                        if (comm.supplierId && comm.queryId === queryObj.Id) {
                                            let a = data.filter((el: any) =>
                                                el['Formulas.organization_id'] == comm.supplierId &&
                                                el['comment'].queryId == queryObj.Id
                                            );
                                            a.forEach((el: any) => {
                                                el.hasComment = true;
                                                el.comment['supplierName'] = comm['supplierName'];
                                                el.comment['supplierId'] = comm['supplierId'];
                                                el.comment['commentDetails'].push({
                                                    createdOn: comm['createdOn'],
                                                    userName: comm['userName'],
                                                    comment: comm['comment']
                                                });
                                            });
                                        }
                                    });
                                }
                                resolve(data);
                            }, error => {
                                reject(error);
                            });
                        } else if (queryObj.Name == "Quarterly Inventory Overview") {
                            const allObservables = forkJoin([
                                this.http.get<any>(this.baseHref + '/getAnalyticsInternalComments')
                            ]);
                            allObservables.subscribe(responses => {
                                let comments = responses[0]; // get comments for factories
                                data = data.map((obj: any) => {
                                    // Check if the factory is in the connected factories list
                                    obj['Formulas.max_scan_date'] = (connectedFactories.map((factory: any) => factory.factory_name)).includes(obj['Formulas.organization_name']) ? obj['Formulas.max_scan_date'] : 'No Scan';
                                    obj.hasComment = false; // by default there is no comment for factories, later will be updated
                                    obj.comment = {
                                        supplierId: obj['Formulas.organization_id'],
                                        supplierName: obj['Formulas.organization_name'],
                                        commentDetails: [],
                                        queryId: queryObj.Id
                                    };
                                    return this.reorderProperties(obj, 'Formulas.max_scan_date');
                                });
                                connectedFactories.forEach((factory: any) => {
                                    if (!factoryNamesInData.includes(factory.factory_name)) {
                                        let newEntry: any = {
                                            'Formulas.organization_name': factory.factory_name,
                                            'Formulas.max_scan_date': 'No Scan',
                                            'Formulas.organization_id': factory.organization_id,
                                            hasComment: false,
                                            comment: {
                                                supplierId: factory.organization_id,
                                                supplierName: factory.factory_name,
                                                commentDetails: [],
                                                queryId: queryObj.Id
                                            }
                                        };
                                        for (let key in data[0]) {
                                            if (key !== 'Formulas.organization_name' && key !== 'Formulas.max_scan_date' && key !== 'Formulas.organization_id' && key !== 'hasComment' && key !== 'comment') {
                                                newEntry[key] = '';
                                            }
                                        }
                                        data.push(newEntry);
                                    }
                                });
                                if (comments.length !== 0) {
                                    comments.forEach((comm: any) => {
                                        if (comm.supplierId && comm.queryId === queryObj.Id) {
                                            let a = data.filter((el: any) =>
                                                el['Formulas.organization_id'] == comm.supplierId &&
                                                el['comment'].queryId == queryObj.Id
                                            );
                                            a.forEach((el: any) => {
                                                el.hasComment = true;
                                                el.comment['supplierName'] = comm['supplierName'];
                                                el.comment['supplierId'] = comm['supplierId'];
                                                el.comment['commentDetails'].push({
                                                    createdOn: comm['createdOn'],
                                                    userName: comm['userName'],
                                                    comment: comm['comment']
                                                });

                                            });
                                        }                              
                                    });
                                }
                                resolve(data);
                            }, error => {
                                reject(error);
                            });
                        } else {
                            resolve(data);
                        }
                    }, error => {
                        reject(error);
                    });
                });
            };

            case "Otto Activity Monitoring": {
                return new Promise((resolve, reject) => {
                    const allObservables = forkJoin([
                        this.getCubeJSData(JSON.parse(queryObj.QueryJSON), undefined),
                        this.http.get<any>(this.baseHref + '/getAnalyticsInternalComments')
                    ]);
                    allObservables.subscribe(responses => {
                        let comments = responses[1]; // get comments for factories
                        data = data.map((obj: any) => { // adjust some of the fields
                            obj.hasComment = false // by default there is no comment for factories, later will be updated
                            obj.comment = {
                                supplierId: obj['BrandActivityWithCompliance.supplier_id'],
                                supplierName: obj['BrandActivityWithCompliance.supplier_name'],
                                commentDetails: []
                            };
                            return obj;
                        });

                        if (comments.length !== 0) {
                            comments.forEach((comm: any) => {
                                let a = data.filter((el: any) => el['BrandActivityWithCompliance.supplier_id'] == comm.supplierId);
                                a.forEach((el: any) => {
                                    el.hasComment = true;
                                    el.comment['supplierName'] = comm['supplierName'];
                                    el.comment['supplierId'] = comm['supplierId'];
                                    el.comment['commentDetails'].push({
                                        createdOn: comm['createdOn'],
                                        userName: comm['userName'],
                                        comment: comm['comment']
                                    });
                                });
                            });
                        }
                        resolve(data);
                    }, error => {
                        reject(error);
                    });
                });
            };             
            case "Higg Overview": case "Wastewater Overview": case "Wastewater Test Reports (v2.1) - Result Analysis": {
                return new Promise((resolve, reject) => {
                    const allObservables = forkJoin([
                        this.getCubeJSData(JSON.parse(queryObj.QueryJSON), undefined),
                        this.http.get<any>(this.baseHref + '/getAnalyticsInternalComments')
                    ]);
                    allObservables.subscribe(responses => {
                        let comments = responses[1]; // get comments for factories and specific query
                        data = data.map((obj: any) => { // adjust some of the fields
                            obj.hasComment = false // by default there is no comment for factories, later will be updated
                            obj.comment = {
                                supplierId: obj['Factories.organization_id'],
                                supplierName: obj['Factories.factory_name'],
                                commentDetails: [],
                                queryId: queryObj.Id
                            };
                            return obj;
                        });

                        if (comments.length !== 0) {
                            comments.forEach((comm: any) => {
                                if (comm.supplierId && comm.queryId === queryObj.Id) {
                                    let a = data.filter((el: any) => el['Factories.organization_id'] == comm.supplierId && el['comment'].queryId == queryObj.Id);
                                    a.forEach((el: any) => {
                                        el.hasComment = true;
                                        el.comment['supplierName'] = comm['supplierName'];
                                        el.comment['supplierId'] = comm['supplierId'];
                                        el.comment['commentDetails'].push({
                                            createdOn: comm['createdOn'],
                                            userName: comm['userName'],
                                            comment: comm['comment']
                                        });
                                    });
                                }
                            });
                        }
                        resolve(data);
                    }, error => {
                        reject(error);
                    });
                });
            };             
                
            case "WasterWater v2.1 parameters": {
                const simplifiedData = data.map((item: any) => ({
                    name: item.name,
                    result: item.result,
                    limit: item.limit,
                    cap: item.cap
                }));

                return simplifiedData;
            }
            default: return Promise.resolve([]);
        }
    }
    private isSpecialQuery(queryObj: DashboardQueryInterface) {
        switch (queryObj.Name) {
            case "Supplier count by compliance rate": return true;
            case "Country Performance": return true;
            case "Otto Activity Monitoring": return true;
            case "Quarterly Inventory Overview": return true;
            case "Monthly Inventory Overview": return true;
            case "Higg Overview": return true;
            case "Wastewater Overview": return true;
            case "Wastewater Test Reports (v2.1) - Result Analysis": return true;
            default: return false;
        }
    }
    handleAction(clickedColumn: any, rowData: any, queryObject: DashboardQueryInterface) {
        let data: any = [];
        switch (clickedColumn.action) {
            case 'showDetails': {
                const dataOrig = clickedColumn.payload.columnData;
                if (queryObject.Name == 'WasteWater v2.1') {
                    let origData = JSON.parse(rowData[dataOrig]);
                    if (clickedColumn.name == 'Sludge') {



                        let convParam = origData.conventionalParams.map((item: any) => ({
                            Type: 'Conventional',
                            name: item.name,
                            detectedValue: item.detectedValue,
                            result: item.result,
                            cap: item.cap
                        }));
                        let anionParam = origData.anionParams.map((item: any) => ({
                            Type: 'Anion',
                            name: item.name,
                            detectedValue: item.detectedValue,
                            result: item.result,
                            cap: item.cap
                        }));
                        let metalPram = origData.metalsParams.map((item: any) => ({
                            Type: 'Metals',
                            name: item.name,
                            detectedValue: item.detectedValue,
                            result: item.result,
                            cap: item.cap
                        }));
                        let mrslParam = origData.mrslParams.map((item: any) => ({
                            Type: 'ZDHC MRSL',
                            name: item.name,
                            detectedValue: item.detectedValue,
                            result: item.result,
                            cap: item.cap
                        })).filter((item: any) => item.result === "Failed");

                        data = [...convParam, ...anionParam, ...metalPram, ...mrslParam]

                    } else {
                        if (clickedColumn.name == 'Conventionals') {
                            origData = JSON.parse(origData.conventionalParameters);
                        }
                        if (clickedColumn.name == 'Anion') {
                            origData = JSON.parse(origData.anionParameters);
                        }
                        if (clickedColumn.name === 'ZDHC MRSL') {
                            data = origData.map((item: any) => ({
                                name: item.name,
                                detectedValue: item.detectedValue,
                                result: item.result,
                                limit: item.limit,
                                cap: item.cap
                            }))
                            data = data.filter((item: any) => item.result === "Failed" || item.result === null);
                        }
                        else {
                            data = origData.map((item: any) => ({
                                name: item.name,
                                detectedValue: item.detectedValue,
                                result: item.result,
                                limit: item.isLeather == true ? (item.leatherLimit ? item.leatherLimit.foundational : null) : (item.textileLimit ? item.textileLimit.foundational : null),
                                cap: item.cap
                            }))
                        }
                    }
                    data = data.sort((a: any, b: any) => {
                        // Handle 'failed' to always come first
                        if (a.result === "Failed" && b.result !== "Failed") return -1;
                        if (b.result === "Failed" && a.result !== "Failed") return 1;

                        // Handle 'null' values to always come last
                        if (a.result === null && b.result !== null) return 1;
                        if (b.result === null && a.result !== null) return -1;

                        // For non-null and non-failed, sort alphabetically or numerically as required
                        if (a.result && b.result) return a.result.localeCompare(b.result);
                        return 0; // Default case to handle unexpected conditions
                    });
                }

                const dialogRef = this.dialog.open(DrillDownDialog, {
                    width: '50%',
                    data: { table: data, description: "This chart shows the list of parameters of " + clickedColumn.name + ' with their result, limit and CAP for factory ' + rowData['Factory Name'], query: queryObject },
                });
            }

        }
    }
    reorderProperties(obj: any, keyToMove: string): any {
        const reorderedObj: any = {};
        const keys = Object.keys(obj);
        const index = keys.indexOf(keyToMove);

        if (index !== -1) {
            // Add 'Formulas.organization_name' first
            if (keys.includes('Formulas.organization_name')) {
                reorderedObj['Formulas.organization_name'] = obj['Formulas.organization_name'];
            }

            // Add the specified key as the second property
            reorderedObj[keyToMove] = obj[keyToMove];

            // Add the remaining keys
            keys.forEach((key, i) => {
                if (key !== 'Formulas.organization_name' && key !== keyToMove) {
                    reorderedObj[key] = obj[key];
                }
            });
        }

        return reorderedObj;
    }

    groupDataByQuarter(data: any) {
        const groupedData: any = {};

        data.forEach((entry: any) => {
            const quarter = entry["Formulas.scan_date.quarter"];
            if (!groupedData[quarter]) {
                groupedData[quarter] = [];
            }
            groupedData[quarter].push({
                factory_name: entry["Factories.factory_name"],
                factory_compliant_pct: entry["Formulas.factory_compliant_pct"]
            });
        });

        return groupedData;
    }

    whoAmI() {
        return this.http.get(this.baseHref + '/whoAmI');
    }
    getAllTranslations(): Observable<any> {
        return this.http.get(this.baseHref + '/getTranslations')
            .pipe(
                catchError(error => {
                    console.error('Error in getTranslations:', error);
                    throw error;
                })
            );
    }

    isValidISODateTime(dateString: any) {
        const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;
        return isoDateTimeRegex.test(dateString);
    }
    downloadWalmartReport() {
        this.http.get(this.baseHref + '/getWalmartReport/' + this.brandid, { responseType: 'blob' }).subscribe(
            (res) => {
                console.info(res)
                let blob = new Blob([res], { type: 'application/application/octet-stream' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'walmart_report_v2.xlsx'
                link.click();
            },
            (error) => {
                if (error['status'] == '500' || error['status'] == '401' || error['status'] == '404') {

                }
            }
        );
    }
    checkFileExists(): Observable<boolean> {
        return this.http.get(this.baseHref + '/checkFileExists/' + this.brandid, { responseType: 'text' }).pipe(
            map((response: string) => {
                return JSON.parse(response).status === 200;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                    return of(false);
                }
                return throwError(error);
            })
        );
    }

    uploadWalmartReport(fileBlob: Blob, fileName: string) {
        if (!fileBlob) {
            console.error('No file to upload');
            return; // Early exit
        }
        if (!this.brandid) {
            console.error('brandid is undefined');
            return; // Early exit
        }
        const formData: FormData = new FormData();
        formData.append('file', fileBlob, fileName);
        formData.append('Id', this.brandid.toString());

        // Return the observable here
        return this.http.post(this.baseHref + '/uploadWalmartReport/' + this.brandid, formData, { responseType: 'text' });
    }

    downloadAndEditWalmartReport(walmart_overview_Data: any[], tuv_bluwin_Data: any[], wm_tuv_bluwin_Data: any[]) {
        // Download the Excel file from the server
        this.http.get(this.baseHref + '/getWalmartReport/' + this.brandid, { responseType: 'blob' }).subscribe(
            (res) => {
                let blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                // Use FileReader to read the Blob as arrayBuffer
                const reader = new FileReader();
                reader.onload = async (e: any) => {
                    const arrayBuffer = e.target.result;

                    // Load the workbook using ExcelJS
                    const workbook = new Workbook();
                    await workbook.xlsx.load(arrayBuffer);

                    // Access worksheets and make modifications
                    const worksheet1 = workbook.getWorksheet('Overview');
                    if (worksheet1) {
                        const row = worksheet1.getRow(4); // Get the specified row
                        let lastColumnIndex = 0; // Initialize the last column index

                        for (let i = 1; i <= row.cellCount; i++) { // Iterate through cells in the row
                            const cell = row.getCell(i); // Get the cell at index i
                            if (cell.value !== null && cell.value !== undefined) { // Check if the cell has a value
                                lastColumnIndex = i; // Update lastColumnIndex
                            }
                        }
                        const today = new Date();
                        const options: Intl.DateTimeFormatOptions = {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        };
                        const formattedDate = today.toLocaleDateString('en-US', options).toUpperCase();
                        worksheet1.unMergeCells(`B1:${String.fromCharCode(64 + lastColumnIndex)}2`);
                        worksheet1.mergeCells(`B1:${String.fromCharCode(64 + lastColumnIndex + 1)}2`);
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}3`).value = formattedDate;
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}3`).font = { bold: true, size: 14 };
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex)}3`).value = 'number increase';
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex + 1)}3`).value = '% increase';
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex)}3`).font = { bold: true, size: 12, italic: true };
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex + 1)}3`).font = { bold: true, size: 12, italic: true };
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}4`).value = walmart_overview_Data[0]['WalmartOverview.total_count'];
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}5`).value = walmart_overview_Data[0]['WalmartOverview.wm_connected'];
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}6`).value = walmart_overview_Data[0]['WalmartOverview.bw_connected'];
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}7`).value = walmart_overview_Data[0]['WalmartOverview.tuv_connected'];
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}8`).value = walmart_overview_Data[0]['WalmartOverview.cil_count'];
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}9`).value = walmart_overview_Data[0]['WalmartOverview.higg_self_count'];
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}10`).value = walmart_overview_Data[0]['WalmartOverview.higg_verified_count'];
                        worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}11`).value = walmart_overview_Data[0]['WalmartOverview.ww_count'];
                        for (let i = 4; i <= 11; i++) {
                            worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex)}${i}`)!.value =
                                Number(worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}${i}`)?.value) -
                                Number(worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 2)}${i}`)?.value);

                        }
                        for (let i = 4; i <= 11; i++) {
                            const cellValue1 = Number(worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 1)}${i}`)?.value);
                            const cellValue2 = Number(worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex - 2)}${i}`)?.value);

                            if (cellValue2 === 0) {
                                worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex + 1)}${i}`)!.value = '0%'; // Or 'N/A', depending on what you'd like to display
                            } else {
                                const percentage = (((cellValue1 - cellValue2) / cellValue2) * 100).toFixed(2) + '%';
                                worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex + 1)}${i}`)!.value = percentage;
                            }
                        }
                        for (let i = 3; i <= 11; i++) {
                            worksheet1.getCell(`${String.fromCharCode(64 + lastColumnIndex + 1)}${i}`).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                        }

                        worksheet1.getCell('B18').value = walmart_overview_Data[0]['WalmartOverview.cil_count'];

                        worksheet1.getCell('B19').value = walmart_overview_Data[0]['WalmartOverview.higg_verified_count'];

                        worksheet1.getCell('B20').value = walmart_overview_Data[0]['WalmartOverview.ww_count'];

                        worksheet1.getCell('C18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === "CIL TUV review") ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === "CIL TUV review")["TuvBluWinReview.count"] : 0;


                        worksheet1.getCell('C19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV review') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV review')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('C20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV review') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV review')["TuvBluWinReview.count"] : 0;;

                        worksheet1.getCell('D18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Approved')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('D19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Approved')["TuvBluWinReview.count"] : 0;
                        worksheet1.getCell('D20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Approved')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('E18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Open questions')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('E19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Open questions')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('E20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Open questions')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('F18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-Data missing')["TuvBluWinReview.count"] : 0;


                        worksheet1.getCell('F19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-Data missing')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('F20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-Data missing')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('G18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL TUV-NA')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('G19').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG TUV-NA')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('G20').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW TUV-NA')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('H18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'TUV review all section') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'TUV review all section')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('I18').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'TUV review at least one section') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'TUV review at least one section')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('B26').value = walmart_overview_Data[0]['WalmartOverview.cil_count'];


                        worksheet1.getCell('B27').value = walmart_overview_Data[0]['WalmartOverview.higg_verified_count'];

                        worksheet1.getCell('B28').value = walmart_overview_Data[0]['WalmartOverview.ww_count'];

                        worksheet1.getCell('C26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === "CIL BW review") ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === "CIL BW review")["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('C27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW review') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW review')["TuvBluWinReview.count"] : 0;
                        worksheet1.getCell('C28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW review') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW review')["TuvBluWinReview.count"] : 0;;

                        worksheet1.getCell('D26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Approved')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('D27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Approved')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('D28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Approved') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Approved')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('E26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Open questions')["TuvBluWinReview.count"] : 0;
                        worksheet1.getCell('E27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Open questions')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('E28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Open questions') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Open questions')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('F26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-Data missing')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('F27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-Data missing')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('F28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Data missing') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-Data missing')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('G26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'CIL BW-NA')["TuvBluWinReview.count"] : 0;


                        worksheet1.getCell('G27').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'HIGG BW-NA')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('G28').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-NA') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'WW BW-NA')["TuvBluWinReview.count"] : 0;

                        worksheet1.getCell('H26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'BW review all section') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'BW review all section')["TuvBluWinReview.count"] : 0;


                        worksheet1.getCell('I26').value = tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'BW review at least one section') ? tuv_bluwin_Data.find((item: any) => item["TuvBluWinReview.status"] === 'BW review at least one section')["TuvBluWinReview.count"] : 0;

                    }
                    const worksheet2 = workbook.getWorksheet('Details');
                    if (worksheet2) {
                        wm_tuv_bluwin_Data.forEach((d: any, index: number) => {
                            const rowIndex = index + 4; // Start from row 4
                            const row = worksheet2.getRow(rowIndex);
                            row.values = Object.values(d); // Update row values with new data
                            row.commit();
                        });
                        worksheet2.eachRow((row, rowNumber) => {
                            row.eachCell((cell: any) => {
                                const value = cell.value.toString();
                                if (value === 'Yes' || value === 'Approved') {
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'C6EFCE' }, // Green
                                    };
                                    cell.font = {
                                        color: { argb: '116100' } // Dark Green text color
                                    };
                                } else if (value === 'No' || value === 'Data missing') {
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FCC7CD' }, // Red
                                    };
                                    cell.font = {
                                        color: { argb: 'A22014' } // Dark Red text color
                                    };
                                }
                                else if (value === 'No' || value === 'Open questions') {
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FDEA9B' }, // Red
                                    };
                                    cell.font = {
                                        color: { argb: 'A5640D' } // Dark Red text color
                                    };
                                }
                            });

                        })
                    }

                    // Once modified, re-upload the edited Excel file
                    workbook.xlsx.writeBuffer().then((updatedData) => {
                        const updatedBlob = new Blob([updatedData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                        this.uploadWalmartReport(updatedBlob, 'Walmart_Report_v2.xlsx')?.subscribe(
                            (res: any) => {
                                console.log('File uploaded successfully', res);

                                // Now download the report after the upload is successful
                                this.downloadWalmartReport();
                            },
                            (error: any) => {
                                console.error('Error uploading file', error);
                            }
                        );
                    }).catch((error) => {
                        console.error('Error updating Excel file', error);
                    });
                };

                // Read the blob as an array buffer to load it into ExcelJS
                reader.readAsArrayBuffer(blob);
            },
            (error) => {
                if (error.status === 500 || error.status === 401 || error.status === 404) {
                    console.error('Error downloading file', error);
                }
            }
        );
    }

}