import { SelectionModel } from '@angular/cdk/collections';
import { AfterContentInit, AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { BhiveDashboardService } from '../bhive-dashboard.service';


export const CONDITIONS_LIST = [
  { value: "is-equal", label: "Is equal" },
  { value: "is-not-equal", label: "Is not equal" },
  { value: "contains", label: "Contains" },
  { value: "does-not-contain", label: "Does not contain" }
];

export const CONDITIONS_FUNCTIONS = {
  // search method base on conditions list value
  "is-equal": function (value: any, filterdValue: any) {
    return value.toLowerCase() == filterdValue.toLowerCase();
  },
  "is-not-equal": function (value: any, filterdValue: any) {
    return value.toLowerCase() != filterdValue.toLowerCase();
  },
  "contains": function (value: any, filterdValue: any) {
    if (typeof value === 'string' && typeof filterdValue === 'string') {
      return value.toLowerCase().includes(filterdValue.toLowerCase());
    }
    return false;
  },
  "does-not-contain": function (value: any, filterdValue: any) {
    if (typeof value === 'string' && typeof filterdValue === 'string') {
      return !value.toLowerCase().includes(filterdValue.toLowerCase());
    }
    return false;
  },
};

@Component({
  selector: 'lib-table-filter-headers',
  templateUrl: './table-filter-headers.component.html',
})
export class TableFilterHeadersComponent implements OnInit {

  @Input() columnName: string = '';
  @Input() dataSource: any;
  public conditionsList = CONDITIONS_LIST;
  public searchValue: any = {};
  public searchCondition: any = {};
  private _filterMethods = CONDITIONS_FUNCTIONS;
  filteredItems: any[] = []
  searchText: string = '';
  uniqueItems: any[] = [];
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['select', 'value'];
  selectedValues: any[] = []
  originalData: any[] = []
  @ViewChild('select') select: MatSelect;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  currentSortDirection: string = '';
  filterApplied: boolean = false;

  constructor(private cubejs: BhiveDashboardService) {  }

  ngOnInit() {
    this.originalData = [...this.dataSource.data];
    this.fillData();
    // this.masterToggle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fillData();
    this.filterApplied = false;

  }

  fillData(): void {
    if (this.dataSource)
    this.dataSource.data.forEach((row: any) => {
      const columnValue = row[this.columnName];
      if( this.selection.selected.some(selectedValue => selectedValue.value === columnValue))
        this.selection.toggle(columnValue)
    })
      this.removeDuplicates();
      this.dataSource.filterPredicate = (p: any, filtre: any) => {
        let result = true;
        let keys = Object.keys(p); // keys of the object data

        for (const key of keys) {
          let searchCondition = filtre.conditions[key]; // get search filter method

          if (searchCondition && searchCondition !== "none") {
            if (
              filtre.methods[searchCondition](p[key], filtre.values[key]) ===
              false
            ) {
              // invoke search filter
              result = false; // if one of the filters method not succeed the row will be remove from the filter result
              break;
            }
          }
        }

        return result;
      };
  }

  //function removes the duplicated values and create a list with unique values used in the search table (checkboxes)
  removeDuplicates(): void {
    const uniqueSet = new Set();
    this.uniqueItems = this.dataSource.data.map((item: any) => {
      const value = item[this.columnName];

      if (!uniqueSet.has(value)) {
        uniqueSet.add(value);
        return { value };
      }

      return null;
    }).filter(Boolean); // Filter out null values
    this.filteredItems = [...this.uniqueItems];
    this.filteredItems = this.filteredItems.sort((a:any, b:any) => {
      if ((a.value === null || a.value === undefined) && (b.value === null || b.value === undefined)) {
        return 0;
      }
      if (a.value === null || a.value === undefined) {
        return -1; // a comes before b
      }
      if (b.value === null || b.value === undefined) {
        return 1; // b comes before a
      }

      // Convert values to numbers
      const numA = parseFloat(a.value);
      const numB = parseFloat(b.value);

      // Check if values are valid numbers
      if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB; // Compare numbers
      }

      // If one or both values are not valid numbers, compare as strings
      const valueA = typeof a.value === 'string' ? a.value.toLowerCase() : a.value;
      const valueB = typeof b.value === 'string' ? b.value.toLowerCase() : b.value;

      return valueA.localeCompare(valueB);
    });
  }     

  //function filters the checkboxes based on the search values
  onInputChange(event: any) {
    const searchInput = event.target.value.toLowerCase();
    if (searchInput == "") {
      this.filteredItems = this.uniqueItems;
    }
    else {
      this.filteredItems = this.uniqueItems.filter(({ value }) => {

        const prov = typeof (value) == 'number' ? value : value?.toLowerCase();
        return prov ? (typeof (value) == 'number' ? prov.toString().includes(searchInput) : prov.includes(searchInput)) : false;
      });
    }
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredItems.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedValues = [];
    } 
    else {
      this.filteredItems.forEach((row: any) => this.selection.select(row));
      this.selectedValues = this.filteredItems;
    }
  }


  applyFilter() {
    const columnKey = this.columnName
    let searchFilter: any;
    const selectedItems = this.selection.selected;

    if (selectedItems.length > 0) { //if there are selected checkboxes
      const filteredData = this.originalData.filter((item: any) => {
        const columnValue = item[this.columnName];
        return selectedItems.some(selectedValue => selectedValue.value === columnValue);
      });
      this.dataSource.data = filteredData;
    }
    else {
      searchFilter = {
        values: this.searchValue,
        conditions: this.searchCondition,
        methods: this._filterMethods,
      };
    this.dataSource.filter = searchFilter;
    }
    const data = [...this.dataSource.data];
    this.cubejs.filterHistory.push({columnKey, data });
    this.applyAllFilters();
    this.filterApplied = true;

  }

  applyAllFilters(): void {
    // Reset the dataSource to the original data
    this.dataSource.data = this.originalData;

    // Apply each filter from the filter history
    this.cubejs.filterHistory.forEach((filter:any) => {
      const { columnKey, data } = filter;

      // Apply the filter for the current iteration
      const filteredData = this.dataSource.data.filter((item: any) => {
        return data.includes(item);
      });

      // Update the dataSource with the filtered data
      this.dataSource.data = filteredData;
    });
  }


  clearColumn(columnKey: string): void {
    this.searchText = '';
    this.searchValue[columnKey] = null;
    this.searchCondition[columnKey] = "none";
    this.selection.clear();
    this.cubejs.filterHistory = this.cubejs.filterHistory.filter(filter => filter.columnKey !== columnKey);
    this.applyAllFilters();
    this.filterApplied = false; 
    // this.masterToggle();
    
  }
  closeMenu() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }
 
  sortData(direction: 'asc' | 'desc', columnName: string): void {
    this.currentSortDirection = direction;
    const sortDirection = this.currentSortDirection;

    this.dataSource.filteredData.sort((a: any, b: any) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      // Handling null values
      if (valueA === null && valueB !== null) {
        return sortDirection === 'asc' ? -1 : 1;
      } else if (valueA !== null && valueB === null) {
        return sortDirection === 'asc' ? 1 : -1;
      }

      // Handling numbers
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        if (valueA === valueB) return 0;
        if (sortDirection === 'asc') {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      }

      // Handling strings
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        const lowercaseA = valueA.toLowerCase();
        const lowercaseB = valueB.toLowerCase();
        if (lowercaseA === lowercaseB) return 0;
        if (sortDirection === 'asc') {
          if (lowercaseA < lowercaseB) return -1;
          return 1;
        } else {
          if (lowercaseA > lowercaseB) return -1;
          return 1;
        }
      }

      // Handle other types here, if necessary
      return 0;
    });

    this.dataSource._updateChangeSubscription();
    this.closeMenu();
  }

}


