export interface DashboardFilterInterface {
  Id: number;
  QueryJSON: string;
  Type: string;
  ShowName: string;
  Name: string;
  Data?: any[]
}

export interface DashboardQueryInterface {
  Id: number;
  Name: string;
  Description: string;
  QueryJSON: string;
  Active: boolean;
  VisualType: number;
  NeedsCompliance: boolean;
  RawQueryId?: number;
  DrillQueryId?: number;
  FilterConfig: {[key:string]:any};
  CanCompare: Object;
  HasTarget: Object;
  // OrganizationTarget?: number;
  GetLatestChemicals: boolean;
  Configs: Array<{
      Code: string;
      QueryPivotConfig: string;
      Groupings: Array<any>;
      InteractionConfig: Object
  }>
}

export interface DashboardStandardInterface {
  Id?: number;
  Name: string;
  DisplayName: string;
}

export interface DashboardVisualTypeInterface {
  Id: number;
  Name: string;
  Code: string;
}

export interface Configs{
  queries: Array<DashboardQueryInterface>,
  standards: Array<DashboardStandardInterface>,
  visuals: Array<DashboardVisualTypeInterface>,
  filters: Array<DashboardFilterInterface>
}

export type visual = | 'bar_chart' | 'group_bar_chart' | 'line_chart' | 'pie_chart' | 'table' | 'number_card' | 'multiline_chart' | 'gauge' | 'stacked_bar_chart' | 'world_map' | 'area_chart';

export enum VisualType {
  BarChart = 'bar_chart',
  GroupBarChart= 'group_bar_chart',
  LineChart = 'line_chart',
  PieChart = 'pie_chart',
  Table = 'table',
  NumberCard = 'number_card',
  MultilineChart = 'multiline_chart',
  Gauge= 'gauge',
  StackedBarChart = 'stacked_bar_chart',
  WorldMap = 'world_map',
  AreaChart = 'area_chart',

}