import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BhiveDashboardItem } from './bhive-dashboard-item.interface';
import { FilterKeys } from './keyword-mapping';

@Component({
    selector: 'compare-dialog',
    templateUrl: 'compare-dialog.component.html',
})
export class CompareDialog {
    today = new Date();
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;

    dateRanges: any[] = [];

    constructor(
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<CompareDialog>,
        @Inject(MAT_DIALOG_DATA) public data: BhiveDashboardItem
    ) { }

    ngOnInit() {
        this.firstFormGroup = this._formBuilder.group({
            comparisonType: [null, Validators.required]
        });

        // Initialize the first date range picker
        this.initializeDateRange();
    }

    // Method to initialize the first date range picker
    initializeDateRange() {
        this.secondFormGroup = this._formBuilder.group({
            firstStartDate: [new Date(), Validators.required],
            firstEndDate: [new Date(), Validators.required],
        });

        // Push the first date range picker into the array
        this.dateRanges.push(this.secondFormGroup);
    }

    // Method to add a new date range picker
    addDateRange() {
        const newFormGroup = this._formBuilder.group({
            firstStartDate: [new Date(), Validators.required],
            firstEndDate: [new Date(), Validators.required],
        });

        // Push the new date range picker into the array
        this.dateRanges.push(newFormGroup);
    }

    // Method to remove a date range picker
    removeDateRange(index: number) {
        // Remove the date range picker from the array
        this.dateRanges.splice(index, 1);
    }

    compareData() {
        const compareDateRanges = this.dateRanges.map((dateRange) =>{
            return [this.formatDate(dateRange.get('firstStartDate')?.value!), this.formatDate(dateRange.get('firstEndDate')?.value!)]
        });
        const datePropertyValue = FilterKeys["Date"];
        let filters = this.data.filters.filter(obj => !("Date" in obj));
        let dashboardQuery = this.data.data;
        let query = JSON.parse(dashboardQuery.QueryJSON);
        query.timeDimensions = [];
        const dimensionName = dashboardQuery.FilterConfig[datePropertyValue as keyof typeof dashboardQuery.FilterConfig]
        query.timeDimensions.push(
            {
                "dimension": dimensionName,
                "compareDateRange": compareDateRanges,
                "granularity": this.firstFormGroup.get('comparisonType')?.value
            })
        this.data.data.QueryJSON = JSON.stringify(query);
        this.data.filters = filters;
    }

    // Utility method to format date
    formatDate(date: Date): string {
        let dateString = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + 1
        ).toISOString().slice(0, 10);
        return dateString;
    }

}
