import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataSharedServiceService } from 'app/globals-interface/datashared.service';

@Component({
  selector: 'app-common-file-upload',
  templateUrl: './common-file-upload.component.html',
  styleUrls: ['./common-file-upload.component.scss']
})
export class CommonFileUploadComponent implements OnInit {
  @Input('accept') accept;
  @Input('allowedFileExtension') allowedFileExtension;
  @Output('onFileSelectEv') onFileSelectEv = new EventEmitter<any>()
  @Input('isMultipleFile') isMultipleFile = false;
  @Input('isProfileImageUpload') isProfileImageUpload = false;

  constructor(
    private dataSharedService: DataSharedServiceService
  ) { }

  ngOnInit(): void {

    this.dataSharedService.commonSubject.subscribe({
      next: (v) => {
        if (v.clearFileUpload) {
          this.uploadedFiles = []
          this.clearContent()
        }
      },
    }
    )
  }

  uploadedFiles: File[] = [];

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    const element = event.target as HTMLElement; // Cast event.target to HTMLElement
    element.classList.add('drag-over');
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const element = event.target as HTMLElement; // Cast event.target to HTMLElement
    element.classList.remove('drag-over');
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.handleFiles(event.dataTransfer?.files);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.handleFiles(input.files);
  }

  handleFiles(files: FileList | null): void {
    if (!files || files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      var fileExt = files[i].name.split('.').pop();
      if (this.allowedFileExtension.length > 0 && this.allowedFileExtension.includes(fileExt)) {
        this.uploadedFiles.push(files[i]);
      }
      if (this.isMultipleFile) {
        this.onFileSelectEv.emit(this.uploadedFiles)
      } else {
        this.onFileSelectEv.emit(this.uploadedFiles[0])
      }


    }
  }

  formatFileSize(size: number): string {
    if (size === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(size) / Math.log(k));

    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  deleteSelectedFile(file) {
    this.uploadedFiles = this.uploadedFiles.filter(f => f != f)
    if (this.isMultipleFile) {
      this.onFileSelectEv.emit(this.uploadedFiles)
    } else {
      this.onFileSelectEv.emit(this.uploadedFiles[0])
    }
    this.clearContent()
  }

  clearContent() {
    var elements = document.getElementsByTagName("input");
    for (var ii = 0; ii < elements.length; ii++) {
      if (elements[ii].type.toLowerCase() == 'file') {
        elements[ii].value = "";
      }
    }
  }
}
