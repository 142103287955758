import { CdkColumnDef } from '@angular/cdk/table';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalsService } from 'app/globals-services/globals.service';
import { CubejsService } from 'app/shared/cubejs/cubejs.service';
import { ExportDataService } from 'app/shared/services/export-data.service';

import * as _moment from 'moment';
import moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

@Component({
  selector: 'app-default-monthly-report',
  templateUrl: './default-monthly-report.component.html',
  styleUrls: ['./default-monthly-report.component.scss'],
  providers: [CdkColumnDef]

})
export class DefaultMonthlyReportComponent implements OnInit {

  organization_name: string;
  @ViewChild('monthlyDataPaginator') monthlyDataPaginator: MatPaginator;
  @ViewChild('monthlyDataMatSort') monthlyDataMatSort: MatSort;
  hasNotData: boolean = false;
  isLoading: boolean = false;
  readonly d = new Date();
  pageSizeOptions: number[] = [10, 50, 100];
  isBrandZdhc = GlobalsService.isBrandZdhcMember;


  constructor(private cubejsService: CubejsService, private exportService: ExportDataService, private globalsService: GlobalsService) { }

  ngOnInit(): void {
    this.organization_name = this.globalsService.profile["name"].toString().toUpperCase();
    this.getMonthlyData(); 
  }

  monthlyData = this.isBrandZdhc ? [
    'organization_id',
    'factory_name',
    'country_name',
    'email',
    'mill_ids',
    'in_scope',
    'is_connected',
    'scanned_two_months_ago',
    'generated_incheck_two_months_ago',
    'two_month_compliance',
    'scanned_prev_month',
    'generated_incheck_prev_month',
    'prev_month_compliance',
    'scanned_current_month',
    'generated_incheck_current_month',
    'current_month_compliance',
    'scanned_at_least_once_in_quarter',
    'prev_quarter_compliance',
    'uploaded_ww1',
    'uploaded_ww2',
    'ww_result',
    'latest_ww_date'
  ]
    :
    [
      'organization_id',
      'factory_name',
      'country_name',
      'email',
      'mill_ids',
      'in_scope',
      'is_connected',
      'scanned_two_months_ago',
      'two_month_compliance',
      'scanned_prev_month',
      'prev_month_compliance',
      'scanned_current_month',
      'current_month_compliance',
      'scanned_at_least_once_in_quarter',
      'prev_quarter_compliance',
      // 'uploaded_ww1',
      // 'uploaded_ww2',
      // 'ww_result',
      // 'latest_ww_date'
    ]


  monthlyDataList = new MatTableDataSource<any>()
  isLoadingMonthlyDataInfo = false;

  getMonthlyData() {
    this.isLoadingMonthlyDataInfo = true;
    const query = {
      "dimensions": this.isBrandZdhc ? [
        "MonthlyReport.organization_id",
        "MonthlyReport.factory_name",
        "MonthlyReport.country_name",
        "MonthlyReport.email",
        "MonthlyReport.mill_ids",
        "MonthlyReport.in_scope",
        "MonthlyReport.is_connected",
        "MonthlyReport.scanned_two_months_ago",
        "MonthlyReport.generated_incheck_two_months_ago",
        "MonthlyReport.two_month_compliance",
        "MonthlyReport.scanned_prev_month",
        "MonthlyReport.generated_incheck_prev_month",
        "MonthlyReport.prev_month_compliance",
        "MonthlyReport.scanned_current_month",
        "MonthlyReport.generated_incheck_current_month",
        "MonthlyReport.current_month_compliance",
        "MonthlyReport.scanned_at_least_once_in_quarter",
        "MonthlyReport.prev_quarter_compliance",
        // "MonthlyReport.uploaded_ww1",
        // "MonthlyReport.uploaded_ww2",
        // "MonthlyReport.ww_result",
        // "MonthlyReport.latest_ww_date",
      ] :
        [
          "MonthlyReport.organization_id",
          "MonthlyReport.factory_name",
          "MonthlyReport.country_name",
          "MonthlyReport.email",
          "MonthlyReport.mill_ids",
          "MonthlyReport.in_scope",
          "MonthlyReport.is_connected",
          "MonthlyReport.scanned_two_months_ago",
          "MonthlyReport.two_month_compliance",
          "MonthlyReport.scanned_prev_month",
          "MonthlyReport.prev_month_compliance",
          "MonthlyReport.scanned_current_month",
          "MonthlyReport.current_month_compliance",
          "MonthlyReport.scanned_at_least_once_in_quarter",
          "MonthlyReport.prev_quarter_compliance",
          // "MonthlyReport.uploaded_ww1",
          // "MonthlyReport.uploaded_ww2",
          // "MonthlyReport.ww_result",
          // "MonthlyReport.latest_ww_date",
        ]
      ,
      "timeDimensions": [],
      "order": {
      },
      "filters": [],
      "measures": [

      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("MonthlyReport.organization_id", "organization_id", tempData)
          tempData = this.changeKey("MonthlyReport.factory_name", "factory_name", tempData)
          tempData = this.changeKey("MonthlyReport.country_name", "country_name", tempData)
          tempData = this.changeKey("MonthlyReport.email", "email", tempData)
          tempData = this.changeKey("MonthlyReport.mill_ids", "mill_ids", tempData)
          tempData = this.changeKey("MonthlyReport.in_scope", "in_scope", tempData)
          tempData = this.changeKey("MonthlyReport.is_connected", "is_connected", tempData)
          tempData = this.changeKey("MonthlyReport.scanned_two_months_ago", "scanned_two_months_ago", tempData)
          tempData = this.isBrandZdhc ? this.changeKey("MonthlyReport.generated_incheck_two_months_ago", "generated_incheck_two_months_ago", tempData) : tempData
          tempData = this.changeKey("MonthlyReport.two_month_compliance", "two_month_compliance", tempData)
          tempData = this.changeKey("MonthlyReport.scanned_prev_month", "scanned_prev_month", tempData)
          tempData = this.isBrandZdhc ? this.changeKey("MonthlyReport.generated_incheck_prev_month", "generated_incheck_prev_month", tempData) : tempData
          tempData = this.changeKey("MonthlyReport.prev_month_compliance", "prev_month_compliance", tempData)
          tempData = this.changeKey("MonthlyReport.scanned_current_month", "scanned_current_month", tempData)
          tempData = this.isBrandZdhc ? this.changeKey("MonthlyReport.generated_incheck_current_month", "generated_incheck_current_month", tempData) : tempData
          tempData = this.changeKey("MonthlyReport.current_month_compliance", "current_month_compliance", tempData)
          tempData = this.changeKey("MonthlyReport.scanned_at_least_once_in_quarter", "scanned_at_least_once_in_quarter", tempData)
          tempData = this.changeKey("MonthlyReport.prev_quarter_compliance", "prev_quarter_compliance", tempData)
          // tempData = this.changeKey("MonthlyReport.uploaded_ww1", "uploaded_ww1", tempData)
          // tempData = this.changeKey("MonthlyReport.uploaded_ww2", "uploaded_ww2", tempData)
          // tempData = this.changeKey("MonthlyReport.ww_result", "ww_result", tempData)
          // tempData = this.changeKey("MonthlyReport.latest_ww_date", "latest_ww_date", tempData)
          tempData = tempData.map(obj => {
            // if (obj.latest_ww_date)
            //   obj.latest_ww_date = obj.latest_ww_date.split('T')[0];
            if (obj.two_month_compliance && obj.two_month_compliance != 'Not Connected')
              obj.two_month_compliance = parseFloat(obj.two_month_compliance).toFixed(2);
            if (obj.prev_month_compliance && obj.prev_month_compliance != 'Not Connected')
              obj.prev_month_compliance = parseFloat(obj.prev_month_compliance).toFixed(2);
            if (obj.current_month_compliance && obj.current_month_compliance != 'Not Connected')
              obj.current_month_compliance = parseFloat(obj.current_month_compliance).toFixed(2);
            if (obj.prev_quarter_compliance)
              obj.prev_quarter_compliance = parseFloat(obj.prev_quarter_compliance).toFixed(2);
            // if (obj.ww_result =='The Waste Water module is not applicable for facilities with external treatment')
            //   obj.ww_result = 'Not Applicable';
            return obj
          });
        }
        else this.hasNotData = true;
        this.monthlyDataList.data = tempData;
        this.monthlyDataList.paginator = this.monthlyDataPaginator;
        this.monthlyDataList.sort = this.monthlyDataMatSort;
        this.isLoadingMonthlyDataInfo = false;
      })
  }

  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = (newKey == "value" && obj[originalKey] === null) ? 0 : obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }

  getCellStyle(date: string): { backgroundColor: string; color: string } {
    if (date) {
      const currentDate = moment();
      const givenDate = moment(date);
      if (givenDate.isSame(currentDate, 'month') || givenDate.isSame(currentDate.clone().add(1, 'month'), 'month')) {
        return { backgroundColor: '#FCECA6', color: '#9F6C2A' };
      } else if (givenDate.isBefore(currentDate, 'month')) {
        return { backgroundColor: '#F6C9CE', color: '#CB7D81' };
      } else if (givenDate.isAfter(currentDate.clone().add(1, 'month'), 'month')) {
        return { backgroundColor: '#C6F0CE', color: '#2A611A' };
      }
      return { backgroundColor: 'transparent', color: 'black' };
    }
  }


  exportData(): void {
    this.exportService.exportDefaultMonthlyReport(this.monthlyDataList.data, this.organization_name + ' Report', this.organization_name, this.isBrandZdhc);

  }

}
