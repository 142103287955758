// import { DashboardConfig } from './bhive-dashboard-config.interface';
import { ChangeDetectorRef, NgZone, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardQueryInterface, DashboardFilterInterface } from './bhive-dashboard-config.interface';
// import { GridsterEmptyCell } from './gridsterEmptyCell.service';
// import { GridsterCompact } from './gridsterCompact.service';
// import { BhiveDashboardConfig } from './bhive-dashboard-config.interface';
import {
  BhiveDashboardItem,
  BhiveDashboardItemComponentInterface
} from './bhive-dashboard-item.interface';
// import * as EventEmitter from 'events';
// import { GridsterRenderer } from './gridsterRenderer.service';

export abstract class BhiveDashboardComponentInterface {
  // $options: DashboardConfig;
  grid: BhiveDashboardItemComponentInterface[];

  name: string;
  editDashboard: () => void;

  checkCollision: (
    item: BhiveDashboardItem
  ) => BhiveDashboardItemComponentInterface | boolean;
  checkCollisionForSwaping: (
    item: BhiveDashboardItem
  ) => BhiveDashboardItemComponentInterface | boolean;
  positionXToPixels: (x: number) => number;
  pixelsToPositionX: (
    x: number,
    roundingMethod: (x: number) => number,
    noLimit?: boolean
  ) => number;
  positionYToPixels: (y: number) => number;
  pixelsToPositionY: (
    y: number,
    roundingMethod: (x: number) => number,
    noLimit?: boolean
  ) => number;
  findItemWithItem: (
    item: BhiveDashboardItem
  ) => BhiveDashboardItemComponentInterface | boolean;
  findItemsWithItem: (item: BhiveDashboardItem) => BhiveDashboardItemComponentInterface[];
  checkGridCollision: (item: BhiveDashboardItem) => boolean;
  checkCollisionTwoItems: (item: BhiveDashboardItem, item2: BhiveDashboardItem) => boolean;
  getItemComponent: (
    item: BhiveDashboardItem
  ) => BhiveDashboardItemComponentInterface | undefined;
  el: HTMLElement;
  renderer: Renderer2;
//   gridRenderer: GridsterRenderer;
  cdRef: ChangeDetectorRef;
  // options: BhiveDashboardConfig;
  calculateLayout$: Subject<void>;
  updateGrid: () => void;
  movingItem: BhiveDashboardItem | null;
  addItem: (item: BhiveDashboardItemComponentInterface) => void;
  removeItem: (item: BhiveDashboardItemComponentInterface) => void;
  previewStyle: (drag?: boolean) => void;
  mobile: boolean;
  curWidth: number;
  curHeight: number;
  columns: number;
  rows: number;
  curColWidth: number;
  curRowHeight: number;
  windowResize: (() => void) | null;
  setGridDimensions: () => void;
  dragInProgress: boolean;
//   emptyCell: GridsterEmptyCell;
//   compact: GridsterCompact;
  zone: NgZone;
  gridRows: Array<number>;
  gridColumns: Array<number>;
}

export interface DashboardJSonModel{
  id:number,
  name:string,
  isHome: boolean,
  items: Array<{
    queryId: number,
    positionX: number,
    positionY: number,
    cols: number,
    rows: number,
    visualCode: string
  }>,
  filters: Array<{
    filterId: number,
    selectedValues: Array<any>
  }>
}

export class DashboardBuilderJson {
  id:number;
  name:string;
  isHome: boolean;
  items: Array<{
    queryId: number,
    positionX: number,
    positionY: number,
    cols: number,
    rows: number,
    visualCode: string
  }>;
  filters: Array<{
    filterId: number,
    selectedValues: Array<any>
  }>;
  constructor(){
    this.id=1;
    this.name = "";
    this.isHome = false;
    this.items = [];
    this.filters = [];
  }
}