// In your library
export class BhiveDashboardEnvironmentService {
    private apiUrl: string;
    private cubeApiUrl: string;

    constructor() { }

    setApiUrl(url: string) {
        this.apiUrl = url;
    }

    getApiUrl() {
        return this.apiUrl;
    }


    setCubeApiUrl(url: string) {
        this.cubeApiUrl = url;
    }

    getCubeApiUrl() {
        return this.cubeApiUrl;
    }
}
